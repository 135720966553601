import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/User";
import Login from '../modules/Login/Login';
import LoginSSO from '../modules/LoginSSO/LoginSSO';
import LoginAuth from '../modules/LoginAuth/LoginAuth'
import Template from "../modules/Template/Template";
import _ from "lodash";
import { TourMaskProvider } from "../components/tourMask/tourMaskHooks";
import TourMask from "../components/tourMask/tourMask";

const Routes = () => {
  const { isLogged } = useUser();
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    if (
      !isLogged &&
      window.location.pathname !== "/" &&
      window.location.pathname.indexOf('/login_auth') === -1 &&
      window.location.pathname.indexOf('/competition') === -1
    ) {
      window.location.href = '/';
    }
  }, [isLogged])

  const { pathname } = window.location;

  useEffect(() => {
    setIsTourOpen(true)
  }, [])

  return <TourMaskProvider>
    {
      (
        pathname.indexOf('/login_auth') > -1 ||
        pathname.indexOf('/competition') > -1
      ) ?
        <LoginAuth /> :
        !isLogged ? ((
          process.env.REACT_APP_STAGE === "local" ||
            process.env.REACT_APP_STAGE === "dev" ? (
            <Login />
          ) : (
            <LoginSSO />
          )
        )) : (
          <Template />
        )
    }
    {isTourOpen &&
      <TourMask
        key={pathname + '/'}
      />
    }
  </TourMaskProvider>
};

export default Routes;
