import React, { useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { ChevronDown, ChevronsRight, ChevronsLeft, LogOut } from 'react-feather';
import logo from '../../logo.png';
import trainingDocuments from '../../assets/trainingDocuments.png';
import serviceNowLogo from '../../assets/servicenow_logo.png';
import templateStyle from './template.module.scss';
import configs from '../../configs';
import modules from '../../modules';
import { useTranslation } from 'react-i18next';
import { SprintPatchNotesModal } from '../../components';
import HeaderDropDown from '../../components/headerDropDown/headerDropDown';
import MenuList from '../../components/menu/Menu';
import AppService from '../../app.service';
import Modal from 'react-modal';
import { useUser } from '../../contexts/User';
import history from './history';
import { createIo } from '../../utils/socketIo';
import ReactTooltip from 'react-tooltip';
import TourMask from '../../components/tourMask/tourMask';
import { TourMaskProvider } from '../../components/tourMask/tourMaskHooks';
import DefaultAvatar from '../../assets/default_avatar.png';
import { useMsal } from '@azure/msal-react';

const Template = () => {
  const { t, i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const { user, removeUser } = useUser();
  const [showMenu, setShowMenu] = useState(user.showMenu ? true : null);
  const [pathname, setPathname] = useState('/');
  const appService = new AppService();
  const { instance, accounts } = useMsal();
  Modal.setAppElement('#root');


  useEffect(() => {
    appService
      .checkCookies()
      .then(res => {
        checkSession(res);
      })
      .catch(err => {
        checkSession(err);
      });

    i18n.changeLanguage(user?.str_language);
    createIo();
  }, []);
  const checkSession = res => {
    if (res.status === 401) {
      if (accounts.length > 0) {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/',
        });
      }
      sessionStorage.removeItem('user');
      window.location.reload(false);
      sessionStorage.removeItem('idtoken');
    }
    return;
  };
  const changeShowMenu = () => {
    if (showMenu !== null) {
      setShowMenu(!showMenu);
    }
  };
  const chevrons = () => {
    if (showMenu) {
      return <ChevronsLeft size={27} />;
    }
    return <ChevronsRight size={27} />;
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setPathname(location.pathname)
      unlisten();
      return () => {
        unlisten()
      }
    });
  })

  const handleLogout = () => {
    if (accounts.length > 0) {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    } else {
      history.push({ pathname: '/' });
    }
    // props.toggle();
    removeUser();
    localStorage.removeItem('taste');
    sessionStorage.removeItem('idtoken');
    localStorage.removeItem('sample');
    localStorage.removeItem('profileLevels');
    sessionStorage.removeItem('menu_active');
    sessionStorage.removeItem('tab_active');
  };

  return (
    <>
      <Router history={history}>
        <div className={templateStyle.template}>
          <div className={templateStyle.templateHeader}>
            <div className={templateStyle.templateLogo}>
              {showMenu != null && (
                <div
                  className={templateStyle.menu}
                  onClick={changeShowMenu}
                  style={{
                    cursor: showMenu != null ? 'pointer' : '',
                  }}
                >
                  {showMenu != null ? chevrons() : null}
                  {t('menu')}
                </div>
              )}
              <img src={logo} alt="ABInBev" height="30" />
            </div>
            <div className={templateStyle.templateUserContent}>
              {process.env.REACT_APP_STAGE !== 'prod' && (
                <div
                  className={templateStyle.templateUser}
                  style={{ color: '#ff6a12', }}
                >
                  <p>TEST ENVIRONMENT &nbsp; v<b>{configs.val_version}</b></p>
                </div>
              )}
              <div className={templateStyle.templateBoxUser}>
                {!user.nonABI &&
                  <>
                    <a
                      className={templateStyle.tempalteLinkServiceNow}
                      target="_blank"
                      href={`https://abinbevww.service-now.com/abiex?id=sc_cat_item&sys_id=5ad2235adb1cc050f17eb29f29961934`}
                      rel="noreferrer"
                    >
                      <img
                        src={serviceNowLogo}
                        style={{
                          height: '16px',
                          marginRight: '26px',
                        }}
                        id='serviceNow'
                        data-tip={`${t('service_now')}`}
                        data-for='serviceNow-tip'
                      />
                      <ReactTooltip
                        id='serviceNow-tip'
                        effect="solid"
                        place="top"
                        type="warning"
                        style={{ whiteSpace: 'normal' }}
                      />
                    </a>
                    <a
                      className={templateStyle.tempalteLinkTraning}
                      target="_blank"
                      href={`https://anheuserbuschinbev.sharepoint.com/:f:/s/SensoryOneAPAC/EhsjOafk5dxNgm3jT0a_l94B7KpwGcfYjmnaUy8yCAn0WA?e=0maJ6U`}
                      rel="noreferrer"
                      style={{ marginRight: '6px' }}
                    >
                      <img
                        src={trainingDocuments}
                        width="22"
                        height="22"
                        data-tip={`${t('training_materials')}`}
                        data-for="training_materials"
                      />
                      <ReactTooltip
                        id="training_materials"
                        effect="solid"
                        place="top"
                        type="warning"
                        style={{ whiteSpace: 'normal' }}
                      />
                    </a>
                  </>
                }
                <div className={templateStyle.templateUserBox}>
                  <div
                    className={templateStyle.templateUser}
                    onClick={toggleDropdown}
                  >
                    <img
                      src={user?.str_image ? `${configs.file_api_url}/v1/${user?.str_image}` : DefaultAvatar}
                      alt="Picture"
                      height="34"
                      width="34"
                    />
                    <span>{user?.str_name}</span>
                    <ChevronDown size={18} />
                  </div>
                  {showDropdown ? (
                    <HeaderDropDown show={setShowDropdown} toggle={toggleDropdown} />
                  ) : null}
                </div>
                <p
                  onClick={() => { handleLogout() }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#B11F24',
                    cursor: 'pointer',
                    marginRight: '15px'
                  }}
                >
                  <LogOut size={18} />
                  &ensp;
                  {t('dropdown_logout')}
                  &ensp;
                </p>
              </div>
            </div>
            <div className={templateStyle.templateUserMinBox}>
              <div className={templateStyle.templateUserBox}>
                <div
                  className={templateStyle.templateUser}
                  onClick={toggleDropdown}
                >
                  <img
                    src={user?.str_image ? `${configs.file_api_url}/v1/${user?.str_image}` : DefaultAvatar}
                    alt="Picture"
                    height="34"
                    width="34"
                  />
                  <span>{user?.str_name}</span>
                  <ChevronDown size={18} />
                </div>
                {showDropdown ? (
                  <HeaderDropDown show={setShowDropdown} toggle={toggleDropdown} minScreen />
                ) : null}
              </div>
            </div>
          </div>
          <div className={templateStyle.tempalteBody}>
            <div
              className={
                showMenu == null
                  ? templateStyle.menuNull
                  : showMenu == true
                    ? templateStyle.showMenu
                    : templateStyle.hideMenu
              }
            >
              <MenuList modules={modules}></MenuList>
            </div>
            <div
              className={
                false
                  ? templateStyle.templateContent + ' ' + templateStyle.hasMenu
                  : templateStyle.templateContent
              }
            >
              {modules.map((module, i) =>
                !module.routeModule.privilege ||
                  (user?.arr_privileges &&
                    user?.arr_privileges.includes(
                      module.routeModule.privilege,
                    )) ? (
                  module.routeModule.context ? (
                    <module.routeModule.context key={'module_' + i}>
                      <Route
                        path={module.routeModule.path}
                        component={module.routeModule.component}
                        key={module.routeModule.key}
                        exact
                      />
                      {module.routeModule.routeComponents.map(component =>
                        !component.privilege ||
                          (user?.arr_privileges &&
                            user?.arr_privileges.includes(
                              component.privilege,
                            )) ? (
                          <Route {...component} key={component.key} />
                        ) : (
                          ''
                        ),
                      )}
                    </module.routeModule.context>
                  ) : (
                    <div key={'module_' + i}>
                      <Route
                        path={module.routeModule.path}
                        component={module.routeModule.component}
                        key={module.routeModule.key}
                        exact
                      />
                      {module.routeModule.routeComponents.map(component =>
                        !component.privilege ||
                          (user?.arr_privileges &&
                            user?.arr_privileges.includes(
                              component.privilege,
                            )) ? (
                          <Route {...component} key={component.key} />
                        ) : (
                          ''
                        ),
                      )}
                    </div>
                  )
                ) : (
                  ''
                ),
              )}
            </div>
          </div>
        </div>
      </Router >
      <SprintPatchNotesModal />
    </>
  );
};

export default Template;
