import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import tabStyle from './tab.module.scss';
import { Button } from '../../components/';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
const Tab = forwardRef((props, ref) => {
  const [selectedTab, setSelectedTab] = useState(props.selectedTabIndex);
  const [colorBorder, setColorBorder] = useState(
    props.selectedTabIndex ?
      `2px solid ${props.tabs.find(item => item.index == props.selectedTabIndex)?.backgroundColor}`
      : '1px solid #DADADA'
  );
  const [saveLoading, setSaveLoading] = useState(false);
  useImperativeHandle(ref, () => ({
    setSelectedTab,
    setSaveLoading
  }));
  const { t } = useTranslation();

  const getClassName = tab => {
    let classes = tabStyle.tab + ' ';
    if (selectedTab === tab.index) {
      classes += tabStyle.selected + ' ';
    }
    const isDisabled = tab.disabled ? tab.disabled : false;
    if (isDisabled) {
      classes += tabStyle.disabled + ' ';
    }
    return classes;
  };
  const setTab = tab => {
    const isDisabled = tab.disabled ? tab.disabled : false;
    if (!isDisabled) {
      setSelectedTab(tab.index);
    }
  };

  const renderTabsStyle = tab => {
    return {
      // width: props.showButton ? '100%' : '',
      display: props.showButton ? 'flex' : '',
      backgroundColor:
        tab.backgroundColor !== undefined ? tab.backgroundColor : '#fff',
      color: tab.fontColor
        ? tab.fontColor
        : selectedTab === tab.index
          ? '#F49B00'
          : '#ababab',
      border: tab.backgroundColor
        ? `2px solid ${tab.backgroundColor}`
        : selectedTab === tab.index
          ? '2px solid #F49B00'
          : '1px solid #DADADA',
      lineHeight: '18px',
    };
  };
  const renderTabs = () => {
    if (props.tabs) {
      return [
        ...props.tabs.map((tab, index) => {
          return index < 10 ? (
            <>
              <div
                className={getClassName(tab)}
                onClick={() => {
                  props.onClick && props.onClick(selectedTab);
                  setTimeout(() => {
                    setTab(tab);
                    if (props.handleType) {
                      props.handleType(tab.description);
                    }

                    if (tab.onClick !== undefined) {
                      tab.onClick();
                    }
                    setColorBorder(
                      tab.backgroundColor
                        ? `2px solid ${tab.backgroundColor}`
                        : '2px solid #DADADA',
                    );
                  }, 200);
                }}
                key={tab.index}
                style={renderTabsStyle(tab)}
                data-tip={tab.dataTip ?? ''}
                data-for={'tabsTips' + tab.index}
              >
                {props.showButton ? (
                  <div className={tabStyle.desc}>{tab.description}</div>
                ) : (
                  tab.description
                )}
              </div>
              {
                tab.dataTip ?
                  <ReactTooltip
                    id={'tabsTips' + tab.index}
                    effect="solid"
                    place="top"
                    type="warning"
                  />
                  : null
              }
            </>
          ) : null;
        }),
        props.showButton && props.tabs.length < 10 && (
          <button
            className={
              props.disabled && props.disabled()
                ? tabStyle.tabDisabledBtn
                : tabStyle.tabButton
            }
            onClick={() => {
              props.clickAdd();
            }}
            disabled={props.disabled && props.disabled()}
          >
            {'+'} {t('new_test')}
          </button>
        ),
      ];
    }
    return null;
  };

  const renderTabContent = () => {
    if (props.tabs && props.tabs.length) {
      const tab = props.tabs.find(tab => {
        return tab.index === selectedTab;
      });

      if (tab) {
        return (
          <div>
            {tab.component()}
            {tab.back || tab.next ? (
              <div
                style={{
                  display: 'flex',
                  padding: '0px 20px',
                  marginRight: '10px',
                }}
              >
                {tab.back && selectedTab !== 1 && (
                  <Button
                    label={t('common_back')}
                    margin="10px 10px 0px 0px"
                    neutral
                    onClick={() => {
                      props.onClick && props.onClick(selectedTab);
                      setTimeout(() => {
                        const previousTab = props.tabs.find(tab => {
                          return tab.index === selectedTab - 1;
                        });
                        setTab(previousTab);
                        if (props.handleType) {
                          props.handleType(previousTab.description);
                        }

                        if (previousTab.onClick !== undefined) {
                          previousTab.onClick();
                        }

                        setColorBorder(
                          previousTab.backgroundColor
                            ? `2px solid ${previousTab.backgroundColor}`
                            : '2px solid #DADADA',
                        );
                      }, 200);
                    }}
                    style={{
                      borderRadius: '6px',
                      width: '160px',
                      justifySelf: 'flex-start',
                    }}
                  />
                )}

                {tab.next && props.tabs.length !== selectedTab && (
                  <Button
                    label={t('common_next')}
                    margin="10px 10px 0px 0px"
                    confirm
                    disabled={
                      props.tabs.find(tab => tab.index === selectedTab + 1)
                        ?.disabled
                        ? true
                        : false
                    }
                    onClick={() => {
                      const nextTab = props.tabs.find(tab => {
                        return tab.index === selectedTab + 1;
                      });
                      setTab(nextTab);
                      if (props.handleType) {
                        props.handleType(nextTab.description);
                      }

                      if (nextTab.onClick !== undefined) {
                        nextTab.onClick();
                      }

                      setColorBorder(
                        nextTab.backgroundColor
                          ? `2px solid ${nextTab.backgroundColor}`
                          : '2px solid #DADADA',
                      );
                    }}
                    style={{
                      borderRadius: '6px',
                      width: '160px',
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  />
                )}
                {tab.save && (
                  <Button
                    label={t('common_submit')}
                    margin="10px 10px 0px 0px"
                    confirm
                    disabled={tab.saveDisabled || tab.allow}
                    loading={saveLoading}
                    onClick={e => {
                      if (tab.onClickSave !== undefined) {
                        tab.onClickSave(e);
                      }
                    }}
                    Ids={tab.tipsShow && selectedTab === 3 ? tab.tipsShow : null}
                    style={{
                      borderRadius: '6px',
                      width: '160px',
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <>
      <div
        className={tabStyle.tabContainer}
        style={{
          justifyContent: props.justifyContent
            ? props.justifyContent
            : 'center',
          width: '100%',
          marginTop: props.marginTop ? props.marginTop : '30px',
        }}
      >
        {renderTabs()}
      </div>
      <div className={tabStyle.tabContent} style={{ border: colorBorder }}>
        {renderTabContent()}
      </div>
    </>
  );
});

Tab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      disabled: PropTypes.bool,
      description: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ),
  selectedTabIndex: PropTypes.number.isRequired,
};

export default Tab;
