export default {
  sample_no_production_date_message:
    'A data de fabricação deve ser selecionada',
  free_comment: 'Comentário livre',
  round_list_rounds: 'Rounds',
  round_edit_round: 'Editar Round',
  round_new_round: 'Novo Round',
  round_remove_round: 'Tem certeza que deseja excluir o Round',
  round_schedule: 'Planejamento',
  round_tasters_unselect_all: 'Desmarcar todos',
  round_tasters_select_all: 'Marcar todos',
  round_close: 'Finalizar Round',
  round_close_message: 'Tens a certeza que queres terminar a ronda',
  round_create_site_message: 'Você está criando uma Round para outra Unidade!',
  round_closed_message: 'Você não pode editar um Round fechado',
  round_status_created: 'Criado',
  round_status_samples: 'Amostras',
  round_status_scheduled: 'Agendamento',
  round_status_tasters: 'Degustadores',
  round_status_in_progress: 'Em progresso',
  round_status_finished: 'Finalizado',
  round_status_closed: 'Fechado',
  round_schedule_start_finish_date:
    'A data de início não pode ser posterior à data de término',
  round_schedule_finish_date_no_start_date:
    'Você deve selecionar uma data de início primeiro',
  round_disabled_discussion_tooltip:
    'Você não pode ir para uma discussão de um Round inacabado',
  round_delete_other_users_rounds:
    'Você não está autorizado a excluir um Round que não criou',
  round_randomize_samples_order_button: 'Embaralhar',
  round_block_results_tooltip:
    'Impedir que os resultados deste Round sejam baixados na tela de relatórios. Se o botão estiver desabilitado, você não tem o privilégio de bloquear os resultados dos Rounds deste local',
  round_block_results_confirm:
    'Tem certeza que deseja bloquear/desbloquear os resultados do Round ',
  round_block_results_no_privilege:
    'Você não tem o privilégio de bloquear / desbloquear os resultados do Round deste local.',
  sample_list_samples: 'Amostras',
  sample_edit_sample: 'Editar Amostra',
  sample_new_sample: 'Nova Amostra',
  sample_remove_sample: 'Remover Amostra',
  sample_upload_document: 'Anexar Documentos',
  sample_download_documents: 'Documentos de amostra',
  sample_tasted_dates: 'Datas de Prova',
  sample_upload_message:
    'Para enviar multiplos arquivos, adicione-os em um .zip',
  sample_not_for_kpi: 'Não é KPI',
  sample_package: 'Embalagem',
  sample_volume: 'Volume',
  sample_production_date: 'Data de Produção',
  sample_expire_date: 'Vencimento',
  sample_trial_identifier: 'Identificador do Ensaio',
  sample_project_name: 'Nome do Projeto',
  sample_linked: 'Em nenhum Round',
  sample_expire_bigger_than_production:
    'A Data de Produção não pode ser maior que a Data de Vencimento',
  sample_no_site_message:
    'É obrigatório selecionar uma Zona, um País e uma Cervejaria para criar uma amostra',
  sample_no_brand_message: 'É obrigatório selecionar uma Marca',
  sample_duplicated_kpi_message:
    'Já existe um KPI para o Mês, Marca e Cervejaria que você selecionou e você não poderá salvar',
  sample_duplicated_project_message:
    'Já existe uma amostra com este Nome do Projeto e Identificador do Ensaio para esta Cervejaria e Marca, e você não será capaz de salvar',
  sample_project_no_name_or_trial:
    'O Nome do Projeto e o Identificador do ensaio são obrigatórios ao criar uma amostra do tipo Projeto',
  sample_production_to_project_confirm_message:
    'Você está alterando o tipo desta amostra de PRODUÇÃO para PROJETO. Você deseja continuar?',
  sample_project_to_production_confirm_message:
    'Você está alterando o tipo desta amostra de PROJETO para PRODUÇÃO. Você deseja continuar?',
  sample_no_volume_message:
    'Embalagem e Volume são obrigatórios ao criar/editar uma amostra.',
  sample_id_code_message: 'Por favor inclua o número completo do lote',
  sample_show_all_samples_hint:
    'Mostrando amostras criadas até um ano a partir de hoje. Marque esta caixa se você quiser ver amostras mais antigas também',
  sample_show_all_samples_label: 'Mostrar Todas As Amostras',
  package_duplicate: 'Já existe uma embalagem com este nome',
  common_production: 'Produção',
  common_project: 'Projeto',
  group_list_groups: 'Grupos',
  group_edit_group: 'Editar Grupo',
  group_new_group: 'Novo Grupo',
  group_remove_group: 'Remover Grupo',
  group_close_modal: 'Fechar',
  group_duplicated_user: 'Este usuário já foi adicionado a este grupo!',
  group_no_name_message: 'É necessário inserir um nome de grupo',
  group_no_zone_message: 'É necessário selecionar uma zona',
  group_no_users_message: 'É necessário selecionar pelo menos um provador',
  family_list_families: 'Estilos',
  family_edit_family: 'Editar Estilo',
  family_new_family: 'Novo Estilo',
  family_remove_family: 'Remover Estilo',
  family_has_no_english_name:
    'Atenção: o nome do Estilo em inglês é mandatório',
  brand_list_brands: 'Marcas',
  brand_edit_brand: 'Editar Marca',
  brand_new_brand: 'Nova Marca',
  brand_remove_brand: 'Remover Marca',
  brand_profile_picture: 'Salvar foto de perfil da marca',
  brand_upload_brand_logo: 'Salvar Logo da Marca',
  brand_duplicate: 'Atenção: Existe uma Marca com este nome',
  brand_has_no_english_name:
    'Deve preencher uma marca e um perfil de cerveja correspondente',
  brand_upload_help_message: 'Somente arquivos PNG e JPG',
  brand_has_no_product_type: 'Você deve selecionar um tipo de produto',
  package_list_packages: 'Embalagens',
  package_edit_package: 'Editar Embalagem',
  package_new_package: 'Nova Embalagem',
  package_remove_package: 'Remover Embalagem',
  package_has_no_english_name:
    'Atenção: o nome em inglês da Embalagem é mandatório',
  package_volumes: 'Volumes',
  package_new_volume: 'Novo Volume',
  package_remove_volume: 'Remover Volume',
  degree_list_degrees: 'Intensidades',
  degree_edit_degree: 'Editar Intensidade',
  degree_new_degree: 'Nova Intensidade',
  degree_remove_degree: 'Remover Intensidade',
  category_list_categories: 'Categorias',
  category_edit_category: 'Editar Categoria de Sabor',
  category_new_category: 'Nova Categoria de Sabor',
  category_remove_category: 'Remover Categoria de Sabor',
  comment_list_comments: 'Atributos',
  comment_edit_comment: 'Editar Atributo de Sabor',
  comment_new_comment: 'Novo Atributo de Sabor',
  comment_remove_comment: 'Remover Atributo de Sabor',
  user_list_users: 'Usuários',
  user_edit_user: 'Editar Usuário',
  user_new_user: 'Novo contratante da ABI',
  user_export_users: 'Exportar Usuários',
  user_list_profiles: 'Perfis',
  user_profile_name: 'Nome do Perfil',
  user_profile_select: 'Selecione um Perfil',
  user_remove_user: 'Remover Usuário',
  user_profile_remove: 'Remover Perfil',
  user_profile_save: 'Salvar Perfil',
  user_use_terms: 'Aceitou Termos de Uso',
  report_list_report: 'Relatórios',
  report_end_date: 'Fim',
  common_privileges: 'Privilégios',
  common_abi_name: 'Nome ABI',
  common_id: 'ID',
  common_ranked: 'Classificada',
  common_week: 'Semana',
  common_weeks: 'Semanas',
  common_days: 'Dias',
  common_category: 'Sabor',
  common_category_sample: 'Categoria',
  common_degree: 'Intensity',
  common_comment: 'Comentário',
  common_status: 'Status',
  common_zone: 'Zona',
  common_country: 'País',
  common_site: 'Cervejaria',
  common_start_date: 'Data de início',
  common_finish_date: 'Data de término',
  common_round_type: 'Tipo de Teste',
  common_round: 'Round',
  common_round_id: 'Round ID',
  common_tasters: 'Degustadores',
  common_sample_performance: 'Desempenho da amostra',
  common_taste: 'Taste',
  common_key_taster: 'Key Taster',
  common_sample: 'Amostra',
  common_samples: 'Amostras',
  common_sample_type: 'Tipo de Amostra',
  common_family: 'Estilo',
  common_brand: 'Marca',
  common_product_type: 'Tipo de Produto',
  common_temperature: 'Condição de armazenamento',
  common_storage_times: 'Tempo de armazenamento',
  common_sku: 'SKU',
  common_batch: 'Código de Identificação',
  common_kpi_month: 'Mês KPI',
  common_logo: 'Logo',
  common_name: 'Nome',
  common_notes: 'Notas',
  common_email: 'E-mail',
  common_language: 'Língua',
  common_terms_of_use: 'Termos de Uso',
  common_profile: 'Perfil',
  common_filter: 'Filtro de Usuários',
  common_download: 'Gerar',
  common_save: 'Salvar',
  common_cancel: 'Cancelar',
  common_search_users: 'Buscar Usuários',
  common_search_group: 'Buscar Grupo',
  common_search_taster: 'Buscar Degustador',
  common_no_data: 'Nenhum registro encontrado',
  common_loading: 'Carregando',
  common_cannot_be_empty: 'O campo {{field}} não pode estar vazio',
  common_select_option: 'Selecione Opção...',
  common_per_page: 'Linhas por página',
  common_attributes: 'Atributos',
  common_not_started: 'Não Iniciado',
  common_in_progress: 'Em progresso',
  common_finished: 'Concluido',
  common_start: 'Inicio',
  common_detail: 'Detalhes',
  common_position: 'Posição',
  common_average_key_score: 'Pontuação chave média ',
  common_score: 'Ponto',
  common_score_scale: 'Escala de pontuação',
  common_intensity: 'Intensidade',
  common_report_not_have_data: 'Os rounds selecionados não tiveram votos',
  month_0: 'Janeiro',
  month_1: 'Fevereiro',
  month_2: 'Março',
  month_3: 'Abril',
  month_4: 'Maio',
  month_5: 'Junho',
  month_6: 'Julho',
  month_7: 'Agosto',
  month_8: 'Setembro',
  month_9: 'Outubro',
  month_10: 'Novembro',
  month_11: 'Dezembro',
  dropdown_change_picture: 'Trocar foto',
  dropdown_language: 'Língua',
  dropdown_logout: 'Sair',
  round_not_started: 'Não Iniciado',
  round_in_progress: 'Em progresso',
  round_finished: 'Concluido',
  round_filter_start: 'Inicio',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Lista de rodadas',
  menu_tasting_session: 'Sessão de Degustação',
  menu_panel_management: 'Painel de Gestão',
  menu_process_management: 'Gestão de Processos',
  menu_product_management: 'Gestão de Produto',
  menu_attributes_management: 'Gestão de Atributos',
  tasting_finish_sample: 'Finalizar Amostra',
  tasting_comments: 'Comentários',
  tasting_beer_profile: 'Cerveja Perfil',
  tasting_need_select_score_comments:
    'Você deve definir uma pontuação e adicionar pelo menos um comentário para continuar ...',
  tasting_finish_taste: 'Finalizar Prova',
  common_no_comments: 'Sem Comentários',
  review_submit_title: 'Você realmente deseja finalizar sua degustação?',
  review_submit_description:
    'Você não poderá editar seus comentários e pontuações depois!',
  review_finish_taste: 'Finalizar Degustação',
  review_warning_sample_title: 'Você tem amostras não degustadas!',
  review_warning_sample_description: 'Você deseja continuar sem avaliá-las?',
  review_warning_sample_review: 'Revisar',
  review_warning_sample_ok: 'Continuar',
  review_warning_tie_sample_title: 'Você tem amostras empatadas!',
  review_warning_tie_sample_description:
    'Você não pode continuar sem ranqueá las!',
  review_warning_tie_sample_review: 'Revisar',
  common_submit: 'Submeter',
  common_discussion: 'Discussão',
  discussion_order_number: 'Ordenar Por Posição',
  discussion_order_brewery: 'Ordenar Por Cervejaria',
  discussion_order_ranking: 'Ordenar Por Ranking',
  discussion_order_brand: 'Ordenar Por Marca',
  discussion_order_score: 'Ordenar Por Pontos',
  discussion_order_key_score: 'Ordenar Por Pontuação Chave',
  discussion_order_overall_rank: 'Classificar por classificação geral',
  discussion_order_key_rank: 'Ordenar por classificação de chave',
  discussion_key_ranking: 'Classificação chave',
  discussion_export_error: 'O arquivo não pode ser salvo!',
  discussion_ranking: 'Classificação',
  discussion_overall_ranking: 'Classificação geral',
  common_brewery: 'Cervejaria',
  common_trial_identifier: 'ID Ensaio',
  common_key_score: 'Pont. Key Taster',
  round_report: 'Export to Excel',
  common_comments: 'Comentários',
  common_message_empty_profile:
    'Nome do perfil não permitido vazio ou em branco',
  common_message_out_score_range: 'pontuação fora da faixa',
  tasting_need_select_score: 'Você deve definir uma pontuação primeiro!',
  common_category_name: 'Categoria',
  categories_new_category: 'Nova categoria',
  categories_remove_category: 'Deseja remover mesmo a categoria',
  categories_list_categories: 'Listagem de Categorias',
  common_category_sample_name: 'Amostras',
  categories_new_category_sample: 'Nova amostra',
  categories_remove_category_sample: 'Deseja remover mesmo a amostra',
  categories_list_categories_samples: 'Listagem de Amostras',
  common_duplicate: 'Duplicar',
  common_collection_point: 'Ponto de Coleta',
  common_collection_date: 'Data de Coleta',
  common_brew_number: 'Número da fabricação/MES número',
  common_need_explain_nok: 'Você não pode ter amostras NOK sem um comentário!',
  common_show_all: 'Mostrar Tudo',
  common_import: 'Importar',
  common_download_template: 'Download modelo',
  common_need_volte_first: 'Você não pode comentar amostras sem antes votar!',
  common_name_template: 'Nome do modelo',
  interaction_log: 'Log Interação',
  common_all: 'Todos',
  common_continue: 'Continuar',
  common_minimum_score: 'Mín',
  common_maximum_score: 'Máx',
  common_average_score: 'Média',
  file_upload_fail:
    'Falha ao fazer o upload do arquivo, os seguintes erros ocorreram: ',
  file_upload_success_with_error:
    'O upload foi bem sucedido exceto pelo seguinte: ',
  missing_fields: 'campos faltando',
  column_error: 'erro de coluna',
  common_line: 'Linha',
  file_no_samples: 'Nenhuma amostra encontrada no arquivo!',
  file_success_upload: 'Sucesso ao fazer o upload do arquivo!',
  file_duplicated_kpi_project: 'KPI ou Projeto duplicado!',
  round_schedule_maximum_length:
    'O tamanho maximo do nome do round é de 40 caracteres.',
  sample_malt_varieties: 'Variedades de Malte',
  malt_varieties_has_no_english_name:
    'Você deve ter pelo menos um nome de variedade de malte.',
  malt_varieties_duplicate:
    'Atenção: Existe uma variedade de malte com este nome',
  malt_varieties_remove: 'Remover variedade de malte',
  common_back: 'Voltar',
  common_next: 'Próximo',
  malt_varieties_new: 'Nova Variedade',
  reports_no_dates: 'Data de início e data de término são obrigatórias!',
  reports_date_range_message: 'A gama de datas não pode ser superior a 1 ano',
  round_save_select_site: 'Para salvar um round você deve selecionar um site',
  common_error: 'Ops! Alguma coisa deu errado. Por favor tente outra vez',
  common_supplier: 'Fornecedor',
  common_flavor: 'Sabor',
  concentration_of_the_base_beer: 'Concentração do produto base',
  common_unit: 'Unidade',
  threshold_aml_method: 'Método AML (Ascending Method of Limits)',
  threshold_component_concentration: 'Concentração do componente',
  threshold_sample_different_help: 'Qual amostra é diferente?',
  threshold_each_set_random_order: 'Em cada Set, uma ordem diferente.',
  threshold_select_base_beer: 'Selecione a Cerveja base',
  common_horizontal: 'Horizontal',
  common_unity: 'Unidade',
  common_finish_session: 'finalizar Sessão',
  review_submit_threshold: 'Tem certeza de que deseja salvar?',
  threshold_coder_wrong: 'Seu código está errado. Informe o código correto!',
  threshold_didnt_enter_the_code: 'Introduza o número do seu lugar',
  code: 'Código',
  common_batch_code: 'Código de lote',
  threshold_samples_required: 'Cerveja base e Sabor são campos obrigatórios',
  threshold_information_helper_sample:
    'Para salvar informações de amostra, clique no botão Salvar para manter suas alterações',
  code_digits: 'Digite o código de 3 dígitos atribuído a você',
  threshold_sample_spiked_with:
    'Em cada conjunto, escolha a amostra que foi enriquecida com ',
  threshold_code_already_tasted:
    'Este código já foi provado por outro usuário. Digite outro código.',
  common_kpi_brands: 'Marcas de KPI',
  common_year: 'Ano',
  common_new_kpi_brand: 'Nova marca de KPI',
  common_action: 'Ação',
  common_comment_by_category: 'Comentários por categoria',
  common_alphabetic_comment: 'Comentários em order alfabética',
  common_name_pts_code: 'Código PTS',
  common_name_pts_code_placeholder: 'Código',
  common_name_process_item: 'Item do Processo',
  common_name_process_item_placeholder: 'Item',

  kpi_only_numbers_warning: 'Informe apenas números.',
  kpi_max_number_range_warning: 'O valor deve ser entre 1 e 9',
  kpi_already_selected_brand_warning:
    'Você já selecionou esta marca, por favor selecione outra.',
  kpi_no_kpi_informed_warning: 'Você não informou nenhum KPI',
  kpi_select_year_warning: 'Selecione o ano',
  kpi_select_site_warning: 'Selecione o site',
  kpi_existing_configuration_warning:
    "Você já tem KPI's para este site e ano cadastrado.",
  kpi_target_empty_warning: 'Um valor para uma das marcas está em branco.',
  kpi_saved_successfully: 'Sua alterações foram salvas com sucesso!',
  kpi_submitted_successfully: 'Sua submição foi enviada com sucesso!',
  kpi_max_decimals_warning: 'Informe até no máximo 3 números decimais.',
  kpi_count_as_kpi_sample: 'Contar como KPI',
  report_list_report_kpi: 'Relatórios KPI',
  report_list_report_taste: 'Relatórios de Degustação',
  select_brand_to_filter: 'Selecione uma Brand Global!',
  select_year_to_filter: 'Selecione um ano!',
  samples_uncheck_kpi_sample_tooltip:
    'Desmarque esta caixa se não quiser que esta amostra conte no KPI',
  reports_mandatory_zone_filter: 'Selecione pelo menos uma zona',
  submission_type: 'Tipo de confirmação',
  custom_scale_title: 'Escala personalizada',
  custom_scale_grid_header_score: 'Pontuação',
  custom_scale_grid_header_ageind_scale: 'Escala de Envelhecimento Forçado',
  the_value_cannot_be_null: 'Valor não pode ser nulo!',
  unique_id: 'Identifica exclusivamente',
  report_filter_rounds_description:
    'Você deve selecionar os campos obrigatórios para carregar as opções de rounds.',
  reports_mandatory_test_type: 'Selecione o tipo de teste.',
  round_reports_month_warning:
    'O período entre a data de início e a data de término deve ser de no máximo 6 meses.',
  zone_can_not_empty: 'A região não pode estar vazia',
  error_loading_data:
    'Erro ao carregar os dados. Verifique sua conexão e recarregue.',
  flavor_identification: 'identificação de Flavor',
  beer_batch: 'Lote de Cerveja',
  list_of_beers: 'Lista de Cerveja',
  random: 'Aleatório',
  preview: 'Prévia',
  select_the_flavor: 'Selecione o flavor',
  flavor_threshold: 'Threshold de flavor',
  input_digital_number: 'Insira o número digital',
  concentration: 'Concentração',
  flavor_order: 'Ordem de flavor',
  last: 'último',
  tasting: 'Desgustação',
  confirmation: 'Confirmação',
  do_you_confirm_the_evaluation_of_this_sample:
    'Você confirma a avaliação desta amostra?',
  confirm: 'Confirmar',
  evaluate: 'Avaliar',
  correct_answer: 'Resposta Correta',
  threshold: 'Threshold',
  let_a_comment_about_the_tasting: 'faça um comentário sobre a degustação',
  send: 'Enviar',
  right_now: 'Imediatamente',
  minute_ago: 'minuto atrás',
  hour_ago: 'hora atrás',
  day_before: 'dia atrás',
  year_ago: 'ano atrás',
  add_a_flavor: 'Adicione um sabor',
  soft_drinks: 'Refrigerantes',
  soft_drinks_brand: 'Marca de refrigerante',
  soft_drinks_brand_target: 'Alvo da marca de refrigerantes',
  column_options: 'Opções de coluna',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Selecione quais das colunas deseja ser exibida.',
  countdown: 'contagem regressiva',
  less_than_five_minutes:
    'Esta sessão de degustação terminará em 5 minutos, se você precisar prosseguir, entre em contato com o administrador para atrasar a data de término.',
  the_countdown_to_the_evaluation_has_ended:
    'A contagem regressiva para comentários terminou. Não é possível acessar os comentários.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Calcule quanto tempo você precisa para provar as amostras',
  only_six_flavors_can_be_added_at_most:
    'Só podem ser adicionados até seis sabores!',
  concentration_of_the_flavor: 'Concentração do sabor',
  input_value: 'Valor de entrada',
  Concentration_of_the_flavor_is_required:
    'A concentração do sabor é necessária',
  previous_round: 'Rodada anterior',
  next_round: 'Próxima rodada',
  the_end_time_is_greater_than_the_current_time:
    'O tempo final deve ser maior do que o tempo atual, se selecionar no mesmo dia que o dia atual, selecione a hora e o minuto primeiro!',
  check_the_answer_at_the_end_of_the_countdown:
    'A contagem regressiva não terminou, você pode ver as respostas após a contagem regressiva',
  incremental_step: 'Etapa Incremental',
  set3_concentration: 'Set3 Concentração',
  concentration_increments_between_levels:
    'Aumentos de concentração entre níveis',
  incremental_set3_concentration_required:
    'Passo incremental e concentração Set3 são necessárias',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'O número da Sprint deve ser maior que 0',
  sprint_notes_at_least_one_note:
    'As notas de sprint devem ter pelo menos uma nota',
  sprint_patch_notes_success_update:
    'Notas de patch do Sprint atualizadas com sucesso.',
  sprint_patch_notes_error_update: 'Não foi possível salvar as notas.',
  update_sprint_patch_notes: 'Atualizar Notas de Patch do Sprint',
  sprint_number: 'Número da corrida',
  sprint_patch_notes: 'Notas do patch de sprint',
  note: 'Nota',
  concentration_increments_between_levels:
    'Aumentos de concentração entre níveis',
  incremental_set3_concentration_required:
    'Passo incremental e concentração Set3 são necessárias',
  select_all: 'Seleccionar Tudo',
  please_add_samples_first: 'Por favor, adicione a amostra primeiro',
  concentration_increments_between_levels:
    'Aumentos de concentração entre níveis',
  incremental_set3_concentration_required:
    'Passo incremental e concentração Set3 são necessárias',
  reverse: 'Reversão',
  new_test: 'Testes adicionados',
  triangle_test_name: 'Nome do teste de triangulação',
  triangle_test: 'Teste de triangulação',
  you_can_not_set_three_identical_samples:
    'Não pode definir as mesmas três opções',
  its_mandatory_to_input_test_name: 'Por favor, preencha o nome do teste',
  its_mandatory_to_input_sample_information:
    'Por favor, preencha as informações da amostra',
  attention: 'rápido',
  test_number: 'Número de série do teste de triangulação',
  you_selected_the: 'Escolheu',
  next_sample: 'Próxima amostra',
  remove_triangle_test: 'Excluir este teste',
  you_can_only_select_up_to_five: 'Um máximo de cinco seleções são permitidas',
  test: 'Teste',
  confirm_remove: 'Confirmar a eliminação do teste?',
  reports_mandatory_zone_country_site_filter:
    'Regiões, países, fábricas são necessárias!',
  reports_mandatory_brand_filter: 'Selecione pelo menos uma marca',
  reports_by_producing_site: 'Relatório de análise completa',
  download_label: 'Botão de descarregamento',
  standard: 'Valor forfetário',
  input_test: 'Entrada de resultados',
  detecting: 'Deteção...',
  connected: 'Ligado',
  current_taster: 'Revisores atuais',
  alcohol_concentration: 'Concentração de álcool',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'A sua concentração de álcool no sangue é excessiva. Por favor, teste novamente mais tarde!',
  alcohol_test_qualified: 'Passar no teste de alcoolemia',
  configuration: 'Disposição',
  input_result: 'Entrada de resultados',
  maximum_alcohol_concentration: 'Excesso de concentração de álcool',
  breathalyzer: 'Detetor de álcool',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Introduza um número positivo até {{field}} casas decimais',
  you_must_input: 'Tem de introduzir',
  driving: 'Condução',
  test_time: 'Tempo de Teste',
  input_type: 'Tipo de Entrada',
  list_breathalyzer: 'Bafômetro',
  Manual: 'Manual',
  Breathalyzer: 'Bafômetro',
  you_do_not_have_privilege_to_export_this_report:
    'Você não tem privilégio de exportar este relatório',
  competitor_beer: 'Cerveja concorrente',
  split_round: 'Dividir a Rodada',
  reset: 'Repor',
  how_many_rounds_splitting_do_you_want_to_make:
    'Quantas rodadas de divisão você quer fazer',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Você não pode dividir uma rodada que já tenha sido dividida ou em andamento',
  hide_sample_details: 'Esconder os Detalhes da Amostra',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Os dados serão ocultos do provador: Cervejaria, Data de produção, Marca, Variedade de malte e ID da amostra',
  data_will_be_hidden_for_ok_nok:
    'Alle relevanten Daten werden vor den Verkostern verborgen.',
  you_can_not_add_duplicate_attributes:
    'Você não pode adicionar atributos duplicados.',
  menu: 'MENU',
  link_breathalyzer: 'Ligado',
  not_link_breathalyzer: 'Desconectado',
  breathalyzer_to_link: 'Ligar o Bafômetro',
  breathalyzer_can_not_to_link_for_this_browser:
    'A capacidade de bafômetro é desenvolvida para funcionar no Google Chrome, usando dispositivos de PC.',
  reopen: 'Reabrir',
  round_reopen_message: 'Tem a certeza que quer reabrir a Ronda',
  common_production_line: 'Linha de produção',
  common_packaged_beer_data_code: 'Código de Dados da Cerveja Embalada',
  common_masking_process_for_bottles: 'Processo de mascaramento para garrafas',
  common_str_number_days_over_aged: 'Número de dias em idade superior',
  common_over_aged_beer: 'Cerveja envelhecida',
  sample_successfully_swapped: 'Amostra trocada com êxito.',
  scoring: 'Pontuação',
  configure_available_flavors: 'Configurar os Flavors Disponíveis',
  process_check: 'Verificação do Processo',
  new_process_check: 'Nova Verificação de Processo',
  edit_process_check: 'Editar a Verificação do Processo',
  remove_process_check: 'Remover a Verificação de Processo',
  common_package: 'Pacote',
  common_department: 'Departamento',
  common_shift: 'Shift',
  common_tank: 'Tanque',
  common_oknok: 'OKNOK',
  common_remark: 'Observação',
  show_all_process_check: 'Mostrar toda a Verificação do Processo',
  show_all_process_check_help_circle:
    'Exibe os dados criados dentro de meio ano a partir de hoje. Se você ainda precisa ver os dados criados dentro de um ano a partir de hoje, marque esta caixa',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'A data da amostra não pode ser anterior à hora atual!',
  common_job_position: 'cargo',
  decimal_sep: 'Separador decimal',
  is_swapped_to: 'é trocado para',
  review_warning_comment_title: 'Você tem amostras não comentadas!',
  continuous_typing: 'Digitação Contínua',
  continuous_typing_tips:
    "Conte digitando 'Atributo' depois de selecionar/digitar 'Intensidade'.",
  BSSI_Reports: 'BSSI Relatórios',
  data_analysis_sip: 'Análise de Dados - SIP',
  select_the_color: 'Selecione a cor',
  Color: 'Cor',
  download_document: 'Arquivos para download',
  Month_of_Discontinued_Production: 'Mês descontinuado',
  Suspension_List: 'Lista descontinuada',
  New_Suspension: 'Novo pedido descontinuado',
  new_suspension_page: 'Nova informação descontinuada',
  edit_suspension_page: 'Editar informações descontinuadas',
  Brand_name: 'Nome da marca',
  List_Brewery_Production_Suspension: 'Lista de fábricas descontinuadas',
  Upload_successfully: 'Arquivo carregado com sucesso',
  training_materials: 'Clique para ver os materiais de treinamento.',
  Remove_Suspension: 'Remover suspensão: ',
  Add_Absence_Reason: 'Adicionar motivo de ausência',
  Reason: 'Razão',
  You_have_duplicate_absence_dates: 'Você tem datas de ausência duplicadas',
  Remove_Absence: 'Remover ausência: ',
  Absence_Title:
    'Por favor, selecione o horário e o motivo da sua ausência de degustação',
  Shipping_Site: 'Local de Envio',
  shipping_received: 'Envio Recebido',
  receive_sample_alert: 'Tem certeza de que recebeu a amostra?',
  receive_uncheck_alert: 'Tem certeza de que deseja desmarcá-lo?',
  all_received: 'Todos Recebidos',
  not_all_received: 'Nem todos receberam',
  not_for_shipping: 'Não para envio',
  receive_hint: 'Selecione todos os sites que receberão este exemplo',
  absence_reason: 'Motivo da Ausência',
  Page1: 'Página {{field}}',
  Search: 'Procurar',
  page_total: 'Número total',
  start_date: 'Data de início',
  end_date: 'Data de término',
  ok_for_all: 'OK para todos',
  its_mandatory_to_select_sample_A_and_sample_B:
    'É obrigatório selecionar a amostra A e a amostra B.',
  its_mandatory_to_select_sample_A: 'É obrigatório selecionar a amostra A.',
  its_mandatory_to_select_sample_B: 'É obrigatório seleccionar a amostra B.',
  triangle_comment: 'Comentário',
  select_sample: 'Selecionar amostra',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Filtro de data de amostra, últimos 7 dias em padrão, exporte para Excel com base nessa duração filtrada.',
  select_all_flavor: 'Selecionar tudo',
  deselect_all: 'Desmarcar tudo',
  email_distribution: 'Distribuição de E-mail',
  new_email_distribution: 'Nova Distribuição de E-mail',
  edit_email_distribution: 'Editar a Distribuição de E-mail',
  distribution_type: 'Tipo de Distribuição',
  the_score_entered_is_invalid:
    'A pontuação introduzida é inválida. Por favor, consulte a escala de pontuação e insira sua pontuação novamente.',
  service_now:
    'Clique para criar um tíquete de incidente para relatar um problema e obter ajuda.',
  service_now_tips:
    'Ei, se você vir algum problema ou gostaria de pedir suporte, você pode clicar aqui e relatá-lo diretamente no serviceNow, nossa equipe de suporte entrará em contato com você o mais rápido possível.',
  done: 'Feito',
  changes_you_made_may_not_be_saved:
    'As alterações que você fez podem não ser salvas. Você quer continuar e perder mudanças?',
  cannel_auto_save_tips:
    'Agora que o desempenho da página de degustação é melhorado, lembre-se de clicar em Finalizar Amostra para salvar e enviar seu resultado de degustação.',
  list_email_page_tips:
    'Esta é a instrução de como configurar a amostra correta do KPI, a fim de garantir que o Relatório Mensal da Zona possa refletir a análise exata para a degustação do MCS da sua zona',
  list_email_page_helps:
    'As instruções de como configurar uma amostra de KPI para refletir dados exatos no Relatório Mensal da Zona.',
  kpi_instruction: 'Instrução do KPI',
  group_no_recipients_message: 'É necessário pelo menos um destinatário.',
  the_current_distribution_type_already_exists:
    'O tipo de distribuição actual já existe, seleccione novamente o tipo ou devolva a lista.',
  add_samples: 'Adicionar amostra(s) à rodada',
  copy_this_sample: `Duplicar a amostra`,
  remove_this_sample: `Remover a amostra`,
  multiple_selection_tips: `Você pode selecionar várias amostras de uma só vez para o mesmo tipo de amostra. Clique em "+" e "-" para experimentar.`,
  all_add_once_tips: `Clique no botão "Select All" (Selecionar tudo) para adicionar automaticamente uma amostra a cada tipo de amostra selecionado.`,
  copy_confirm_tip: `Tem certeza de que deseja replicar a amostra?`,
  user_non_abi_user: 'Novo usuário temporário',
  language_of_email_invitation: 'Idioma do convite por e-mail',
  add_non_abi_users_to_round:
    'O usuário temporário receberá um convite por e-mail com um link para participar da degustação específica.',
  change_non_abi_users_to_round:
    'O degustador temporário que você convidou receberá um convite por e-mail com um link para participar dessa degustação.',
  guest: 'Convidado',
  email_to_all: 'E-mail para todos',
  send_email: 'Enviar e-mail de convite',
  temporary_non_abi_taster: 'Provador temporário',
  user_type: 'Tipo de usuário',
  email_invitation_delivery_request_sent:
    'Solicitação de convite por e-mail enviada.',
  incorrect_e_mail_formatting: 'Formatação incorreta do e-mail',
  all_tasters: 'Todos os Provadores',
  key_tasters: 'Provadores Principais',
  non_key_tasters: 'Provadores Não Principais',
  temporary: 'Usuário temporário',
  maximum_number_of_samples_is: `O número máximo de amostras é`,
  sample_added: 'Amostra adicionada',
  click_to_view_scale_of_flavour_intensities: `Clique no link para ver as escalas de intensidade detalhadas`,
  list_next_week_plan: 'Plano para a próxima semana',
  common_time_zone: 'Fuso horário',
  common_last_response_time: `Hora da última resposta`,
  new_week_plan: 'Plano da nova semana',
  invited_week: 'Semana convidada',
  email_language: `Idioma do e-mail`,
  save_and_send_email: `Salvar e enviar e-mail`,
  common_start_time: 'Hora de início',
  common_end_time: 'Hora de término',
  session_name_email_subject: `Nome da sessão / Assunto do e-mail`,
  location: 'Localização',
  edit_week_plan: 'Editar plano da semana',
  email_response: 'Resposta por e-mail',
  round_remove_plan: 'Tem certeza de que deseja apagar a rodada',
  view_week_plan: 'Exibir plano semanal',
  are_you_confirming_to_send_the_confirmation_email: `Você está confirmando o envio do e-mail de confirmação`,
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation: `A cada 10 minutos, o sistema atualizará a resposta de e-mail do convite do plano da próxima semana`,
  documents_upload_tips: 'Os uploads de arquivos suportam apenas {{field}}',
  ignore_submit: 'Ignorar&Enviar',
  if_count_as_kpi:
    'Se conta como KPI, a amostra é guardada no histoêrico de amostras',
  confirmed_count: 'Contagem confirmada',
  accepted: 'Aceito',
  declined: 'Recusado',
  no_response: 'Sem resposta',
  reminder_time: 'Hora do lembrete',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    'Tem certeza de que deseja remover o horário de início/fim da sessão que selecionou e mudar para outra semana convidada',
  apply_to_all_sessions_location_tasters: `Aplique 'Localização' e 'Tasters' às seguintes sessões`,
  mcs_tasting: 'Degustação MCS',
  mcs_tasting_tips:
    'Selecione Yes (Sim) se essa rodada contiver amostras MCS/KPI, o sistema aplicará o Código KPI relacionado de acordo com a marca da amostra.',
  round_samples_shuffle_confirm:
    'Tem certeza de que deseja embaralhar os conjuntos?',
  all_count_as_kpi: 'Todos contam como KPI',
  msc_tasting_tour_mask:
    'O sistema identificará automaticamente o código de KPI relacionado com base na amostra contada com o mês de KPI correto, para que você NÃO precise dividir marcas diferentes em uma rodada',
  competition: 'Concorrência',
  copy_link: 'Copiar link',
  open_link: 'Abrir link',
  common_download_img: `Baixar o IMG`,
  please_select_taster: 'Selecione a degustação',
};
