import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label, TextField, Spinner } from '..';
import { Search, X } from 'react-feather';
import dropdownStyle from './dropdownfield.module.scss';
import { useTranslation } from 'react-i18next';
import CardRow from '../card/CardRow';
import { HelpCircle } from 'react-feather';
import ReactTooltip from 'react-tooltip';

const DropdownField = props => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setShow(props.show);
  }, []);

  useEffect(() => {
    const optionsTemp = props.options ? props.options : [];
    setSelected(null);
    const selectedTemp = optionsTemp.filter
      ? optionsTemp.filter(option => {
        return props.value && option[props.valueField] === props.value;
      })
      : [];

    //IF ALREADY SELECTED SELECT IT
    if (selectedTemp.length > 0) {
      setSelected(selectedTemp[0]);
    }
  }, [props.value]);

  useEffect(() => {
    const optionsTemp = props.options ? props.options : [];
    const order = typeof props.bolOrder != 'undefined' ? props.bolOrder : true;
    if (order) {
      setOptions(
        optionsTemp.sort((a, b) => {
          if (a[props.labelField] > b[props.labelField]) {
            return 1;
          }
          if (a[props.labelField] < b[props.labelField]) {
            return -1;
          }
          return 0;
        }),
      );
    } else {
      setOptions(optionsTemp);
    }

    setSelected(null);
    const selectedTemp = optionsTemp.filter
      ? optionsTemp.filter(option => {
        return props.value && option[props.valueField] === props.value;
      })
      : [];
    //IF ALREADY SELECTED SELECT IT
    if (selectedTemp.length > 0) {
      setSelected(selectedTemp[0]);
    }
    //IF ONLY ONE OPTION ALREADY SELECT IT
    if (
      optionsTemp.length === 1 &&
      selectedTemp.length === 0 &&
      props.isRequired
    ) {
      setSelected(optionsTemp[0]);
      const res = {
        target: {
          value: optionsTemp[0][props.valueField],
          name: props.name,
        },
      };
      props.onChange(res);
    }
  }, [props.options])

  const handleChangeDropdown = evt => {
    if (evt && evt.target) {
      let optionsTemp = props.options.filter(option => {
        return (
          option[props.labelField] &&
          option[props.labelField]
            .toString()
            .trim()
            .toLowerCase()
            .indexOf(evt.target.value?.toString().trim().toLowerCase()) !== -1
        );
      });
      setText(evt.target.value);
      setOptions(optionsTemp);
    }
  };

  const handleSelect = (evt, option) => {
    const res = {
      target: {
        value: option[props.valueField],
        name: props.name,
        uuid: option[props.uuid] || null,
        uuidName: props.uuid || null,
      },
    };
    setShow(false);
    props.onChange(res, props.id);
  };

  const handleShowList = () => (
    <div>
      <div
        className={
          props.isTable
            ? dropdownStyle.dropdownListTable
            : dropdownStyle.dropdownList
        }
      >
        <div>
          <Search />
          <TextField
            name="str_text"
            margin="-8px 0px 0px 0px"
            padding="8px 2px 8px 30px"
            value={text}
            onChange={handleChangeDropdown}
          />
        </div>
        {options.map((option, index) =>
          selected &&
            selected[props.valueField] === option[props.valueField] ? (
            <div className={dropdownStyle.dropdownOptionSelected} key={index}>
              {handleShowRowComponent(option)}
              {!props.hideRemoveButton && (
                <X
                  onClick={evt => {
                    handleSelect(evt, {
                      ...option,
                      [props.valueField]: null,
                      [props.uuid]: null,
                    });
                  }}
                  size={18}
                />
              )}
            </div>
          ) : (
            <div
              className={dropdownStyle.dropdownOption}
              key={index}
              onClick={evt => {
                handleSelect(evt, option);
              }}
            >
              {handleShowRowComponent(option)}
            </div>
          ),
        )}
      </div>
      {!props.hidedropdownOuterList && (
        <div
          className={dropdownStyle.dropdownOuterList}
          onClick={evt => {
            setShow(false);
          }}
        ></div>
      )}
    </div>
  );

  const handleShowRowComponent = option => {
    return <div className={dropdownStyle.rowItem}>
      {option[props.labelField] && <Label className={dropdownStyle.label} label={option[props.labelField]} />}
      {option.imgUrl && <img src={option.imgUrl} className={dropdownStyle.img} width={option.imgWidth ?? "32px"} height={option.imgHeight ?? ''} />}
    </div>;
  };

  return (
    <div
      style={{
        margin: props.margin ? props.margin : '10px',
        flex: props.flex ? props.flex : 1,
        ...props.style,
      }}
      className={`${props.headerSearch
        ? dropdownStyle.headerContainer
        : dropdownStyle.dropdownContainer
        } ${props.disabled ? dropdownStyle.cursorNotAllow : ''}`}
      id={props.id ?? null}
    >
      <CardRow
        style={{
          background: 'none',
        }}
      >
        <Label
          label={props.label ? props.label : ''}
          required={props.isRequired}
          style={props.labelStyle}
        />
        {props.isLoading ? (
          <Spinner
            styles={{
              width: '15px',
              height: '15px',
              margin: '0 5px',
            }}
          />
        ) : (
          <></>
        )}
        {props.toolTip && (
          <div className={dropdownStyle.SvgBox}>
            <HelpCircle
              className={dropdownStyle.helpCircle}
              data-tip={props.toolTip}
            />
            <ReactTooltip
              effect="solid"
              place="top"
              type="warning"
              className={dropdownStyle.tooltip}
            />
          </div>
        )}
      </CardRow>
      {
        <div
          onClick={
            props.disabled
              ? null
              : evt => {
                setShow(!show);
              }
          }
          className={`${props.headerSearch
            ? dropdownStyle.headerSelectionContainer
            : dropdownStyle.dropdownSelectionContainer
            } ${props.disabled ? dropdownStyle.cursorNotAllowColor : ''}`}
        >
          {selected
            ? selected[props.labelField]
            : props.placeholder
              ? props.placeholder
              : t('common_select_option')}
          <span
            style={{
              display: props.hideArrow ? 'none' : 'block',
              top: props.arrowTop,
            }}
            className={
              props.isTable
                ? dropdownStyle.dropdownArrowTable
                : dropdownStyle.dropdownArrow
            }
          ></span>
        </div>
      }
      {show ? handleShowList() : null}
    </div>
  );
};

DropdownField.propTypes = {
  options: PropTypes.array.isRequired,
  valueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  hideArrow: PropTypes.bool,
  bolOrder: PropTypes.bool,
  flex: PropTypes.number,
  disabled: PropTypes.bool,
  isTable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default DropdownField;

