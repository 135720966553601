import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import tastingTriangle from './tastingTriangle.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Label,
  CardColumn,
  CardRow,
  Button,
  TextField,
  Modal,
  Text,
  RoundUpAndDown,
  CountDown,
  IntensityScale,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useTasting } from '../../../contexts/Tasting';
import beer from '../../../assets/beer_threshold.svg';
import RoundAppService from '../../services/rounds/roundApp.service';
import uuid from 'react-uuid';
import PinyinMatch from 'pinyin-match';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import TriangleService from '../../services/triangleTest/triangle.service';
import _ from 'lodash';
import { ArrowLeft, HelpCircle } from 'react-feather';
import UserService from '../../services/users/user.service';
import ReactTooltip from 'react-tooltip';
import { useUser } from '../../../contexts/User';
import moment from 'moment';
import { GetSocket } from '../../../utils/socketIo';
const fuzzysort = require('fuzzysort');

function Comments (props) {
  const { t } = useTranslation();
  const triangleService = new TriangleService();
  const [commentsList, setcommentsList] = useState(
    props.item.sample_comment ? props.item.sample_comment : [],
    [],
  );
  const [comment, setComment] = useState({});
  const [attrs, setAttrs] = useState([]);
  const [nextAttrs, setNextAttrs] = useState([]);
  const [searchIntensitylist, setSearchIntensitylist] = useState([]);
  const [active, setActive] = useState({
    Intensity: null,
    AttrTitle: null,
    AttrClass: null,
  });
  const [searchData, setSearchData] = useState('');

  const common_intensity = useRef(null);
  const common_attributes = useRef(null);
  const [isContinuous, setIsContinuous] = useState(false);
  const userService = new UserService();
  const { user } = useUser();
  useEffect(() => {
    alphabetical();
    userService
      .getUserContinuousTyping()
      .then(res => {
        if (res.results.length == 0) {
          setIsContinuous(false);
        } else {
          setIsContinuous(res.results[0].bol_continuous_type);
        }
      })
      .catch(error => { });
  }, []);

  const categoryList = () => {
    setAttrs(props.categories.categories);
    const attr = props.categories?.categories.filter(item => {
      return item.label === 'Flavor/Aroma';
    });
    nextCategoryList(
      attr.length === 0 ? props.categories.categories[0] : attr[0],
    );
    setActive(prevState => {
      return { ...prevState, AttrTitle: 'left' };
    });
  };

  const alphabetical = () => {
    setAttrs(props.categories.aphabe);
    const arr = props.categories?.aphabe?.filter(item => {
      return item.label === 'All';
    });
    if (arr) {
      nextCategoryList(
        arr?.length === 0 ? props.categories?.aphabe[0] : arr[0],
      );
    }
    setActive(prevState => {
      return { ...prevState, AttrTitle: 'right' };
    });
  };

  const nextCategoryList = arr => {
    let filterComments = [];
    if (arr.value.length <= 10) {
      if (arr?.label === 'All') {
        filterComments = props.attrs;
      } else {
        if (user.str_language === 'zh') {
          filterComments = props.attrs.filter(
            item =>
              PinyinMatch.match(item.str_name, arr.value).length > 0 &&
              PinyinMatch.match(item.str_name, arr.value)[0] === 0,
          );
        } else {
          filterComments = props.attrs.filter(item => {
            return (
              item.str_name.slice(0, 1).toUpperCase() ===
              arr.label.toUpperCase()
            );
          });
        }
      }
      setActive(prevState => {
        return { ...prevState, AttrClass: arr?.label };
      });
    } else {
      filterComments = props.attrs.filter(item => {
        return (
          item.uuid_category === arr.value &&
          item.str_name_comments_categories === arr.label
        );
      });
      setActive(prevState => {
        return { ...prevState, AttrClass: arr?.label };
      });
    }
    const orderedComments = filterComments.sort((a, b) =>
      a.str_name.localeCompare(b.str_name),
    );

    const map = new Map();
    for (const item of orderedComments) {
      if (!map.has(item.str_name) && !map.has(item.uuid_comment)) {
        map.set(item.str_name, item);
      }
    }
    setNextAttrs([...map.values()]);
  };

  const saveComments = obj => {
    setComment({});
    setSearchData('');
    clear();
    setcommentsList(prevState => {
      return [...prevState, obj];
    });
    triangleService.updateSampleComment(obj);
  };

  const handleTypingChange = () => {
    setIsContinuous(!isContinuous);
    userService.updateUserContinuousTyping({
      bol_continuous_type: !isContinuous,
    });
  };

  const handleRow = (dataObj, changeType) => {
    const obj1 = {
      uuid_degree: comment?.uuid_degree,
      str_comment_name: comment?.str_comment_name
        ? comment?.str_comment_name
        : dataObj.str_name,
      uuid_comment: dataObj?.uuid_comment,
      str_degree: comment?.str_degree,
      str_comment: dataObj?.str_comment ? dataObj.str_comment : '',
      uuid_answers_triangle_samples_comments: uuid(),
      int_order:
        commentsList[commentsList.length - 1]?.int_order + 1 ||
        commentsList.length + 1,
      bol_del: false,
      uuid_answers_rounds_users_sample: props.item.uuid_sample_sub,
    };

    if (dataObj && dataObj.uuid_degree) {
      obj1.uuid_degree = dataObj?.uuid_degree ? dataObj?.uuid_degree : '';
      obj1.str_degree = dataObj?.str_name;
      if (
        dataObj?.str_comment &&
        !commentsList.some(item => item.str_comment === dataObj?.str_comment)
      ) {
        saveComments(obj1);
        setSearchIntensitylist([]);
      }
      if (!dataObj?.str_comment) {
        setSearchIntensitylist([]);
      }
      setActive(prevState => {
        return { ...prevState, Intensity: dataObj?.uuid_degree };
      });
      setComment(prevState => {
        return { ...prevState, ...obj1 };
      });
      if (isContinuous) {
        setTimeout(() => {
          common_attributes.current.focus();
        });
      }
    }

    if (dataObj.uuid_comment || dataObj.str_comment) {
      if (
        dataObj.str_comment
          ? !commentsList.some(item => item.str_comment === dataObj.str_comment)
          : !commentsList.some(
            item => item.uuid_comment === dataObj.uuid_comment,
          )
      ) {
        obj1.uuid_comment = dataObj?.uuid_comment;
        obj1.str_comment_name = dataObj?.str_name;

        setComment(prevState => {
          return { ...prevState, ...obj1 };
        });
        saveComments(obj1);
        if (changeType === 'click') {
          common_attributes.current && common_attributes.current.blur();
          common_intensity.current && common_intensity.current.blur();
        } else if (isContinuous) {
          common_intensity.current && common_intensity.current.focus();
        }
      } else {
        window.alert(t('you_can_not_add_duplicate_attributes'));
      }
    }
    setSearchData('');
    if (!isContinuous) {
      common_intensity.current && common_intensity.current.blur();
      common_attributes.current && common_attributes.current.blur();
    }
    return true;
  };

  const select = (dataObj, changeType) => {
    _.debounce(handleRow(dataObj, changeType), 770);
  };

  const delComment = async (id, comment) => {
    const obj1 = {
      uuid_degree: comment.uuid_degree ? comment.uuid_degree : '',
      str_comment_name: comment?.str_comment_name
        ? comment?.str_comment_name
        : '',
      uuid_comment: comment.uuid_comment,
      str_comment: comment.str_comment,
      uuid_answers_triangle_samples_comments:
        comment.uuid_answers_triangle_samples_comments,
      int_order: comment.int_order,
      bol_del: true,
      uuid_answers_rounds_users_sample:
        comment.uuid_answers_rounds_users_sample,
    };
    const res = await triangleService.updateSampleComment(obj1);
    setcommentsList(prevState => {
      return prevState.filter(item => {
        return item.uuid_answers_triangle_samples_comments !== id;
      });
    });
  };

  const search = e => {
    let value = e.target.value;

    if (value) {
      let filterArr = [...props.degrees, ...props.attrs];
      let searchList = [];
      if (comment.str_degree) {
        filterArr = [...props.attrs];
      }

      if (
        filterArr.some(
          item => item.str_name.toUpperCase().indexOf(value.toUpperCase()) > -1,
        )
      ) {
        searchList = [
          { str_comment: value },
          ...fuzzysort
            .go(value, filterArr, { key: 'str_name' })
            .map(a => a.obj),
        ];
      } else {
        searchList = [{ str_comment: value }];
      }
      setSearchIntensitylist(searchList);
    }
    setSearchData(value);
  };
  const code13 = e => {
    const code = e.keyCode || e.which || e.charCode;
    if (code === 13) {
      select(
        searchIntensitylist.length > 1
          ? searchIntensitylist[1]
          : searchIntensitylist[0],
      );
    }
  };

  const pullDown = () => {
    return (
      <ul className={tastingTriangle.searchlist}>
        {searchIntensitylist.map((item, index) => {
          return (
            <li key={index} onClick={() => select(item, 'click')}>
              {item.str_name || item.str_comment}
            </li>
          );
        })}
      </ul>
    );
  };

  const clear = () => {
    setSearchIntensitylist([]);
    setSearchData('');
    setActive(prevState => {
      return { ...prevState, Intensity: null };
    });
    setComment({});
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const move = arrayMove(commentsList, oldIndex, newIndex);
    const arr = move.map((comment, index) => {
      comment = {
        uuid_answers_triangle_samples_comments:
          comment.uuid_answers_triangle_samples_comments,
        int_order: index + 1,
      };
      return comment;
    });
    const res = await triangleService.updateCommentOrder(arr);
    const newItems = move.map((comment, index) => {
      comment.int_order = index + 1;
      return comment;
    });
    setcommentsList(newItems);
  };

  const SortableItem = SortableElement(({ comment, index }) => {
    return (
      <div key={index} className={tastingTriangle.SortableItem}>
        {
          <div
            className={
              comment.uuid_comment
                ? tastingTriangle.ButtonList
                : tastingTriangle.ButtonListCustom
            }
          >
            {comment.str_comment ? (
              <p>
                {comment.str_degree ? comment.str_degree + ' - ' : ''}
                {comment.str_comment}
              </p>
            ) : (
              <p>
                {comment.str_degree ? comment.str_degree + ' - ' : ''}
                {comment.str_comment_name}
              </p>
            )}
            <button
              onClick={() => {
                delComment(
                  comment.uuid_answers_triangle_samples_comments,
                  comment,
                );
              }}
            >
              X
            </button>
          </div>
        }
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className={tastingTriangle.SortableList}>
        {items.map((comment, index) => (
          <SortableItem
            //disabled
            key={index}
            comment={comment}
            index={index}
          />
        ))}
      </div>
    );
  });
  const off = async () => {
    const arr = JSON.parse(JSON.stringify(props.ListCode));
    arr.forEach(item => {
      if (
        item.arr_sample[props.i].uuid_sample_sub === props.item.uuid_sample_sub
      ) {
        item.arr_sample[props.i].sample_comment = commentsList;
      }
    });

    props.setListCode(arr);
    props.setCommentShow(false);
    props.refreshState();
  };
  const stylelist = T => {
    return {
      color: active.AttrTitle === T ? '#fff' : 'rgb(113, 111, 113)',
      border: active.AttrTitle === T ? '0' : '1px solid rgb(198, 198, 198)',
      background: active.AttrTitle === T ? 'rgb(244, 156, 45)' : '#fff',
    };
  };

  return (
    <div
      className={tastingTriangle.comments1}
      onClick={e => e.target.className.indexOf('comments1') > -1 && off()}
    >
      <div
        className={tastingTriangle.comments2}
        onClick={e => e.target.className.indexOf('comments2') > -1 && off()}
      >
        <CardColumn className={tastingTriangle.commentsCard}>
          <div
            className={'iconfont ' + tastingTriangle.commentx}
            onClick={() => off()}
          >
            &#xe61d;
          </div>
          <h3 className={tastingTriangle.hh}>{t('common_comments')}</h3>
          <CardRow padding="10px 0px 0px 0px">
            <CardColumn className={tastingTriangle.commentsContent}>
              <SortableList
                items={commentsList}
                onSortEnd={onSortEnd}
                axis="xy"
              />
              <div className={tastingTriangle.commentsSearch}>
                {searchIntensitylist.length > 0 && pullDown()}
                {comment.str_degree ? (
                  <div style={{ display: 'flex' }}>
                    <p style={{ whiteSpace: 'nowrap', marginRight: '4px' }}>{comment.str_degree + ' - '}</p>
                    <input
                      ref={common_attributes}
                      placeholder={t('common_attributes')}
                      className={tastingTriangle.Input}
                      onChange={e => search(e)}
                      onKeyDown={e => code13(e)}
                      value={searchData}
                    />
                  </div>
                ) : (
                  <input
                    ref={common_intensity}
                    placeholder={
                      (comment?.str_degree
                        ? comment.str_degree
                        : t('common_intensity')) +
                      ' - ' +
                      t('common_attributes')
                    }
                    className={tastingTriangle.Input}
                    onChange={e => search(e)}
                    onKeyDown={e => code13(e)}
                    value={searchData}
                  />
                )}
                <span
                  onClick={() => clear()}
                  className={'iconfont ' + tastingTriangle.clear}
                >
                  &#xe61d;
                </span>
              </div>
            </CardColumn>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Label
                style={{
                  whiteSpace: 'nowrap',
                }}
                label={t('continuous_typing')}
                bold
              ></Label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <input
                  type="checkbox"
                  checked={isContinuous}
                  onChange={() => {
                    handleTypingChange();
                  }}
                  className={tastingTriangle.checke}
                />
                <HelpCircle
                  data-tip={t('continuous_typing_tips')}
                  style={{ color: '#B11F24', marginLeft: 5 }}
                  size="20"
                  data-for="continuous_typing_tips"
                />
                <ReactTooltip
                  id="continuous_typing_tips"
                  effect="solid"
                  place="bottom"
                  type="warning"
                  style={{ whiteSpace: 'normal' }}
                />
              </div>
            </div>
          </CardRow>
          <CardRow
            style={{ flexDirection: 'column' }}
            className={tastingTriangle.IntensityScale}
          >
            <CardRow  >
              <CardColumn>
                <IntensityScale />
              </CardColumn>
            </CardRow>
            <CardRow flexWrap="wrap" flex={1} style={{ overflow: 'auto' }}>
              {props.degrees.map((item, index) => {
                return (
                  <Button
                    className={
                      active.Intensity === item.uuid_degree
                        ? tastingTriangle.activeAttrClassList
                        : tastingTriangle.attrClassList
                    }
                    label={item.str_name}
                    key={item.uuid_degree + index}
                    onClick={() => select(item, 'click')}
                  />
                );
              })}
            </CardRow>
          </CardRow>
          <CardRow
            style={{ flexDirection: 'column', flex: '1', overflow: 'auto' }}
            className={tastingTriangle.IntensityScale}
          >
            <CardRow>
              <CardColumn>
                <Label label={t('common_attributes')} bold></Label>
              </CardColumn>
            </CardRow>
            <div
              style={{
                flex: 1,
                overflowY: 'auto',
              }}
            >
              <CardRow>
                <Button
                  label={t('common_comment_by_category')}
                  style={stylelist('left')}
                  className={tastingTriangle.commentsClass}
                  onClick={categoryList}
                />
                <Button
                  label={t('common_alphabetic_comment')}
                  style={stylelist('right')}
                  className={tastingTriangle.commentsClass}
                  onClick={alphabetical}
                />
              </CardRow>
              <CardRow style={{ flexWrap: 'wrap' }}>
                {attrs?.map((item, index) => {
                  return (
                    <Button
                      label={item.label}
                      style={{
                        width: active.AttrTitle === 'right' ? '50px' : '120px',
                        background:
                          active.AttrClass === item.label
                            ? 'rgb(244, 156, 45)'
                            : '#666',
                      }}
                      className={tastingTriangle.commentsClassList}
                      key={item.value + index}
                      onClick={() => nextCategoryList(item)}
                    >
                      {active.AttrClass === item.label ? (
                        <p className={tastingTriangle.triangle}></p>
                      ) : null}
                    </Button>
                  );
                })}
              </CardRow>
              <CardRow
                style={{
                  flex: '1',
                  flexWrap: 'wrap',
                  alignContent: 'flex-start',
                }}
              >
                {nextAttrs.map((item, index) => {
                  return (
                    <Button
                      label={item.str_name}
                      className={tastingTriangle.commentsButtonList}
                      key={item.uuid_comment + index}
                      onClick={() => select(item, 'click')}
                    />
                  );
                })}
              </CardRow>
            </div>
          </CardRow>
        </CardColumn>
      </div>
    </div >
  );
}

export default function TastingTriangle (props) {
  const roundAppService = new RoundAppService();
  const triangleService = new TriangleService();
  const [code, setCode] = useState('');
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowNextModal, setIsShowNextModal] = useState(false);
  const [i, setI] = useState();
  const [commentShow, setCommentShow] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const history = useHistory();
  const [listSet, setListSet] = useState([]);
  const [curSet, setCurSet] = useState({});
  const [curIndex, setCurIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [showWarningEmptySamples, setShowWarningEmptySamples] = useState(false);
  const [isShowFinishModal, setIsShowFinishModal] = useState(false);
  const [round, setRound] = useState(props.location.state?.round);
  const [isCountDownShow, setIsCountDownShow] = useState(false);
  const { updateFinishRound } = useTasting();
  const { user } = useUser();

  useEffect(() => {
    refreshState();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('updateFinishDateForTriangle', function (res) {
        if (window.location.pathname === '/tasting_triangle') {
          const nowRound = props.location.state.round.uuid_round === res.uuid_round;
          if (nowRound) {
            setRound({ ...props.location.state.round, ...res });
            history.replace({
              pathname: `/tasting_triangle`,
              state: { round: { ...props.location.state.round, ...res } },
            });
          }
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const updateList = async () => {
    const resSample = await triangleService.listTriangleSample({
      uuid_round: round.uuid_round,
    });
    setListSet(resSample.results[0]?.user_sample);
    const curSample = resSample.results[0]?.user_sample.find(
      item => item.bol_status === 0,
    );
    setCurSet(curSample);
    setSelectedIndex(+curSample.arr_sample[0].str_sample);
    if (round.bol_is_countdown) {
      setIsCountDownShow(true);
    } else {
      setIsCountDownShow(false);
    }
  };

  const refreshState = async () => {
    roundAppService.listDegrees().then(res => {
      setDegrees(res);
    });
    roundAppService.listComments().then(res => {
      const reduceArr = res.reduce((accumulator, currentValue) => {
        const existingObject = accumulator.find(
          obj => obj.uuid_comment === currentValue.uuid_comment,
        );
        if (!existingObject) {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      setAttrs(reduceArr);
    });
    roundAppService.listCommentsCategory().then(res => {
      setCategories(res);
    });
    const codeRes = await triangleService.getTriangleCode({
      uuid_round: round.uuid_round,
    });
    if (!codeRes) {
      setDisabled(false);
    }
    if (
      codeRes &&
      codeRes?.results[0]?.round_sp_se_triangle_test_current_code
    ) {
      setCode('00' + codeRes.results[0].round_sp_se_triangle_test_current_code);
      setDisabled(true);
      updateList();
    }
  };
  const saveCode = async code => {
    const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const res = await triangleService.updateTriangleSet({
      uuid_round: round.uuid_round,
      str_code: code,
    });
    if (res && res.type === 'success') {
      if (res.results[0].round_sp_se_round_triangle_test === '1') {
        setCode('00' + code);
        const res = await roundAppService.updateStart(
          props.location.state.round,
          timeNow,
        );
        setDisabled(true);
        updateList();
        if (round.bol_is_countdown) {
          setIsCountDownShow(true);
        } else {
          setIsCountDownShow(false);
        }
      } else if (res.results[0].round_sp_se_round_triangle_test === '0') {
        alert(t('threshold_code_already_tasted'));
        setDisabled(false);
      } else {
        alert(t('threshold_coder_wrong'));
      }
    }
  };

  const delayedQuery = useCallback(
    _.debounce(text => {
      if (text.length > 2) {
        setCode(text.replace(/^0+/, ''));

        let num = text.toString();
        const size = 3;

        while (num.length < size) num = '0' + num;

        setCode(num);
        saveCode(text.replace(/^0+/, ''));
      } else {
        setCode(text);
      }
    }, 10),
    [],
  );
  const commentSet = (e, index) => {
    setI(index);
    setCommentShow(true);
    e.stopPropagation();
  };

  const handleSubmit = async () => {
    const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const res = await roundAppService.updateFinish(
      props.location.state.round,
      timeNow,
    );
    if (res) {
      updateFinishRound();
      history.replace({
        pathname: `/tasting_discussion_triangle/${round.uuid_round}`,
        state: {
          round: props.location.state.round,
        },
      });
    }
  };

  const handleClickCard = async (e, index, item) => {
    if (e.target.className === 'tastingTriangle_commentList__wOqLD') {
      return;
    }
    const obj = {
      uuid_round: round.uuid_round,
      uuid_sample: curSet?.uuid_sample,
      sample_num: index,
    };
    const res = await triangleService.updateSampleAnswer(obj);
    if (res) {
      setSelectedIndex(index);
      updateList();
    }
  };

  const handleGoNextSample = async () => {
    const res = await triangleService.updateSampleStatus({
      uuid_round: round.uuid_round,
      uuid_sample: curSet?.uuid_sample,
    });
    if (res) {
      updateList();
    }
    if (curIndex < listSet.length) {
      setCurIndex(curIndex + 1);
    }
  };

  const chooseSample = num => {
    switch (num) {
      case 0:
        return `${t('common_sample')} A`;
      case 1:
        return `${t('common_sample')} B`;
      case 2:
        return `${t('common_sample')} C`;
      default:
        break;
    }
  };

  const handleClickNext = () => {
    const isFind = curSet.arr_sample.some(item => {
      return item.str_sample;
    });
    if (isFind) {
      setIsShowNextModal(true);
    } else {
      setShowWarningEmptySamples(true);
    }
  };

  const goNext = () => {
    setIsShowNextModal(false);
    handleGoNextSample();
  };

  const handleBack = () => {
    history.replace('/');
  };

  const renderButton = () => {
    return <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      {listSet.length > 1 ? (
        <Button
          className={
            curSet?.int_order_app === listSet?.length &&
              selectedIndex !== ''
              ? tastingTriangle.NotFinishButton
              : tastingTriangle.finishButton
          }
          label={t('next_sample')}
          remove
          margin="0px 24px 0px 0px"
          onClick={handleClickNext}
          disabled={
            curSet?.int_order_app === listSet?.length &&
            selectedIndex !== ''
          }
        ></Button>
      ) : (
        ''
      )}
      <Button
        className={
          curSet?.int_order_app === listSet?.length
            ? tastingTriangle.finishButton
            : tastingTriangle.NotFinishButton
        }
        label={t('tasting_finish_sample')}
        confirm
        onClick={() => {
          if (
            curSet?.int_order_app === listSet?.length &&
            listSet?.length === 1
          ) {
            setIsShowModal(true);
          } else {
            const isFind = curSet?.arr_sample.some(item => {
              return item.str_sample;
            });
            if (isFind) {
              setIsShowModal(true);
            } else {
              setIsShowFinishModal(true);
            }
          }
        }}
        disabled={curSet?.int_order_app !== listSet?.length}
      ></Button>
    </div>
  };

  return (
    <div>
      <Modal className={tastingTriangle.Modal} show={isShowModal}>
        <div className={tastingTriangle.modalContainer}>
          <h2 className={tastingTriangle.line1}>{t('attention')}</h2>
          {listSet?.length > 1 ? (
            <span className={tastingTriangle.line2}>
              {t('test_number')}:{' '}
              <span>
                {curSet?.int_order_app}/{listSet?.length}
              </span>
            </span>
          ) : (
            ''
          )}
          <div className={tastingTriangle.line3}>
            {t('you_selected_the')} <span> {chooseSample(selectedIndex)}</span>
          </div>
          <div className={tastingTriangle.buttonContainer}>
            <Button
              className={tastingTriangle.cancelButton}
              label={t('common_cancel')}
              remove
              margin="0px 10px 0px 0px"
              onClick={() => {
                setIsShowModal(false);
              }}
            ></Button>

            <Button
              className={
                curSet?.int_order_app === listSet?.length
                  ? tastingTriangle.confirmButton
                  : tastingTriangle.disabledButton
              }
              label={t('confirm')}
              confirm
              onClick={handleSubmit}
            ></Button>
          </div>
        </div>
      </Modal>
      <Modal className={tastingTriangle.Modal} show={isShowNextModal}>
        <div className={tastingTriangle.modalContainer}>
          <h2 className={tastingTriangle.line1}>{t('attention')}</h2>
          {listSet?.length > 1 ? (
            <div className={tastingTriangle.line2}>
              {t('test_number')}:{' '}
              <span>
                {curSet?.int_order_app} /{listSet?.length}
              </span>
            </div>
          ) : (
            ''
          )}
          <div className={tastingTriangle.line3}>
            {t('you_selected_the')} <span> {chooseSample(selectedIndex)}</span>
          </div>
          <div className={tastingTriangle.buttonContainer}>
            <Button
              className={tastingTriangle.cancelButton}
              label={t('common_cancel')}
              remove
              margin="0px 10px 0px 0px"
              onClick={() => {
                setIsShowNextModal(false);
              }}
            ></Button>

            <Button
              className={tastingTriangle.confirmButton}
              label={t('confirm')}
              confirm
              onClick={goNext}
            ></Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={tastingTriangle.showModal}
        show={showWarningEmptySamples}
      >
        {showWarningEmptySamples ? (
          <Fragment>
            <CardRow className={tastingTriangle.cardRowModal}>
              <Text className={tastingTriangle.h1Title}>
                {t('review_warning_sample_title')}
              </Text>
            </CardRow>
            <CardRow className={tastingTriangle.cardRowModal}>
              <Text className={tastingTriangle.h3Title}>
                {t('review_warning_sample_description')}
              </Text>
            </CardRow>
            <CardRow
              className={
                tastingTriangle.buttonsWrapper +
                ' ' +
                tastingTriangle.cardRowModal
              }
            >
              <Button
                label={t('review_warning_sample_review')}
                remove
                onClick={() => setShowWarningEmptySamples(false)}
              ></Button>
              <Button
                label={t('review_warning_sample_ok')}
                confirm
                onClick={() => {
                  setShowWarningEmptySamples(false);
                  handleGoNextSample();
                }}
              ></Button>
            </CardRow>
          </Fragment>
        ) : (
          ''
        )}
      </Modal>
      <Modal className={tastingTriangle.showModal} show={isShowFinishModal}>
        {isShowFinishModal ? (
          <Fragment>
            <CardRow className={tastingTriangle.cardRowModal}>
              <Text className={tastingTriangle.h1Title}>
                {t('review_warning_sample_title')}
              </Text>
            </CardRow>
            <CardRow className={tastingTriangle.cardRowModal}>
              <Text className={tastingTriangle.h3Title}>
                {t('review_warning_sample_description')}
              </Text>
            </CardRow>
            <CardRow
              className={
                tastingTriangle.buttonsWrapper +
                ' ' +
                tastingTriangle.cardRowModal
              }
            >
              <Button
                label={t('review_warning_sample_review')}
                remove
                onClick={() => setIsShowFinishModal(false)}
              ></Button>
              <Button
                label={t('review_warning_sample_ok')}
                confirm
                onClick={() => {
                  setIsShowFinishModal(false);
                  handleSubmit();
                }}
              ></Button>
            </CardRow>
          </Fragment>
        ) : (
          ''
        )}
      </Modal>

      <CardRow transparent>
        <CardColumn className={tastingTriangle.header}>
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingTriangle.back}
          >
            <ArrowLeft />
          </Button>
          {
            !user.nonABI &&
            <RoundUpAndDown round={round} />
          }
        </CardColumn>
        <CardColumn flex={5} style={{ display: 'flex', }}>
          <div className={tastingTriangle.cardLabelTitle}>
            <div className={tastingTriangle.topLabel}>
              {props.location.state.round.int_round}<br />
              {props.location.state.round.str_name_round}
            </div>
          </div>
        </CardColumn>
        <CardColumn>
          <CardColumn className={tastingTriangle.countDownColumn}>
            <div className={tastingTriangle.countDownDiv}>
              {round.bol_is_countdown && isCountDownShow ? (
                <CountDown round={round} save={handleSubmit} />
              ) : null}
            </div>
          </CardColumn>
        </CardColumn>
      </CardRow>

      <CardRow className={tastingTriangle.maxScreen} transparent flexWrap="wrap" margin="20px 0px 0px 5px">
        <CardColumn padding="5px" flex={5}>
          <TextField
            name="flt_concentration"
            label={t('code')}
            margin="10px 10px 0px 0px"
            value={code}
            onChange={evt => {
              delayedQuery(evt.target.value);
            }}
            text="number"
            disabled={disabled}
            placeholder={t('code_digits')}
          />
        </CardColumn>
      </CardRow>
      <CardRow itemAlign='center' className={tastingTriangle.minScreen} transparent flexWrap="nowrap" margin="20px 0px 0px 5px">
        {
          !disabled ? (
            <CardColumn padding="5px" flex={5}>
              <TextField
                name="flt_concentration"
                label={t('code')}
                margin="10px 10px 0px 0px"
                value={code}
                onChange={evt => {
                  delayedQuery(evt.target.value);
                }}
                text="number"
                disabled={disabled}
                placeholder={t('code_digits')}
              />
            </CardColumn>
          ) : (
            <>
              <CardColumn padding="5px" flex={3}>
                <h2 className={tastingTriangle.code}>
                  {t('code')}{' '}
                  {code}
                </h2>
              </CardColumn>
              <CardColumn padding="5px" flex={5}>
                {renderButton()}
              </CardColumn>
            </>
          )
        }
      </CardRow>
      {code ? (
        <CardRow
          transparent
          flexWrap="wrap"
          padding="15px 0px 0px 10px"
          justifyContent="space-between"
          className={tastingTriangle.buttonRow}
        >
          <CardColumn padding="5px">
            <div className={tastingTriangle.titleNum}>
              {listSet.length > 1 ? (
                <span className={tastingTriangle.order}>
                  {curSet?.int_order_app}/{listSet?.length}
                </span>
              ) : (
                ''
              )}
              <span className={tastingTriangle.titleSpan}>
                {curSet?.str_triangle_test_name}
              </span>
            </div>
          </CardColumn>
          <CardColumn className={tastingTriangle.maxScreen} padding="5px 12px 0px 0px" flex justifyContent="flex-end">
            {renderButton()}
          </CardColumn>
        </CardRow>
      ) : (
        ''
      )}
      {code ? (
        <CardRow
          transparent
          flexWrap="wrap"
          padding="30px 0px 0px 0px"
          justifyContent="center"
          className={tastingTriangle.minScreenSample}
        >
          {curSet &&
            curSet?.arr_sample?.map((item, index) => {
              return (
                <div
                  className={
                    item.str_sample && +item?.str_sample === index
                      ? tastingTriangle.selectedCard
                      : tastingTriangle.setCard
                  }
                  onClick={e => {
                    handleClickCard(e, index, item);
                  }}
                >
                  <div className={tastingTriangle.optionSet}>
                    <div> {chooseSample(index)}</div>
                  </div>
                  <div className={tastingTriangle.optionSelect}>
                    <div className={tastingTriangle.optionContainer}>
                      <img
                        src={beer}
                        alt={'unlocked'}
                        height={44}
                        width={30}
                        style={{ marginLeft: '-10px' }}
                      />
                    </div>
                  </div>
                  <div className={tastingTriangle.comment}>
                    {t('common_comments')}
                  </div>
                  <div className={tastingTriangle.answerContainer}>
                    {item.sample_comment?.length > 0 ? (
                      <div className={tastingTriangle.commentList}>
                        {item.sample_comment?.map((item, index) => {
                          return item.str_comment_name || item.str_comment ? (
                            <button
                              key={index}
                              className={
                                item.str_comment_name
                                  ? tastingTriangle.showComments
                                  : tastingTriangle.noComment
                              }
                            >
                              {item.str_comment ? (
                                <p>
                                  {item.str_degree
                                    ? item.str_degree + ' - '
                                    : ''}
                                  {item.str_comment}
                                </p>
                              ) : (
                                <p>
                                  {item.str_degree
                                    ? item.str_degree + ' - '
                                    : ''}
                                  {item.str_comment_name}
                                </p>
                              )}
                            </button>
                          ) : (
                            ''
                          );
                        })}
                      </div>
                    ) : (
                      <li className={tastingTriangle.commentList}></li>
                    )}
                  </div>

                  <div className={tastingTriangle.commentButtonContainer}>
                    <Button
                      className={tastingTriangle.commentButton}
                      label={t('common_comments')}
                      confirm
                      onClick={e => commentSet(e, index)}
                    ></Button>
                  </div>
                </div>
              );
            })}
        </CardRow>
      ) : (
        ''
      )}
      {commentShow &&
        curSet?.arr_sample?.map((item, index) => {
          return (
            index === i && (
              <Comments
                key={index}
                comments={item?.arr_comments}
                item={item}
                ListCode={listSet}
                degrees={degrees}
                attrs={attrs}
                categories={categories}
                setListCode={setListSet}
                refreshState={() => {
                  updateList();
                }}
                setCommentShow={setCommentShow}
                i={i}
                code={code}
                round={round.uuid_round}
                selectedIndex={selectedIndex}
              ></Comments>
            )
          );
        })}
    </div>
  );
}

