import React, { useRef, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import sensoryOne from '../../assets/default_avatar_white.png'
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
import { Copy, Download, Navigation } from 'react-feather';
import html2canvas from 'html2canvas';

const CustomQRCode = ({
  value,
  size,
  imageSrc,
  imageWidth,
  imageHeight,
  fileName = 'QRCode',
  isDownload = false,
  isShowLink = false,
  isCopyLink = false,
  renderTitle,
}) => {
  const { t } = useTranslation();
  const [isCopy, setIsCopy] = useState(false);
  const saveImg = useRef(null);
  const downloadCanvasQRCode = async () => {
    try {
      const node = saveImg.current;
      const canvas = await html2canvas(node);
      const img = canvas.toDataURL('image/png');

      const a = document.createElement('a');
      a.href = img;
      a.download = fileName + '.png';
      a.click();
    } catch (error) {
      console.error('Error exporting image:', error);
    }
  }
  const openLink = () => {
    window.open(value, '_blank');
  }
  const copyLink = () => {
    navigator.clipboard.writeText(value);
    setIsCopy(true)
    setTimeout(() => {
      setIsCopy(false)
    }, 500);
  }
  const renderFlexStyle = () => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
  return (
    <div id='myQRcode'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <div
        ref={saveImg}
        style={{
          padding: '5px'
        }}>
        {renderTitle ? renderTitle() : null}
        <QRCodeCanvas
          value={value}
          size={size ?? 256}
          level='H'
          imageSettings={{
            src: imageSrc ?? sensoryOne,
            width: imageWidth ?? 60,
            height: imageHeight ?? 60,
          }}
        />
      </div>
      <div>
        {
          isDownload ?
            <Button
              confirm
              margin="30px 5px"
              padding="10px"
              onClick={() => {
                downloadCanvasQRCode();
              }}
            >
              <div style={renderFlexStyle()}>
                <Download size={14} />&ensp;{t('common_download')}
              </div>
            </Button>
            : null
        }
        {
          isShowLink ?
            <Button
              confirm
              margin="30px 5px"
              onClick={() => {
                openLink();
              }}
            >
              <div style={renderFlexStyle()}>
                <Navigation size={14} />&ensp;{t('open_link')}
              </div>
            </Button>
            : null
        }
        {
          isCopyLink ?
            <Button
              confirm
              green={isCopy}
              margin="30px 5px"
              onClick={() => {
                copyLink();
              }}
            >
              <div style={renderFlexStyle()}>
                <Copy size={14} />&ensp;{t('copy_link')}
              </div>
            </Button>
            : null
        }
      </div>
    </div>
  );
}

export default CustomQRCode;