export default {
  round_round_type_in_profile: 'В профілі',
  round_round_type_freshness: 'Свіжіѝть',
  round_not_started: 'Не розпочатий',
  round_in_progress: 'У процеѝі',
  round_finished: 'Закінчений',
  round_filter_start: 'Почавши',
  round_list_rounds: 'Спиѝок Раундів',
  round_new_round: 'Новий раунд',
  round_remove_round: 'Видалити раунд',
  round_schedule: 'Графік',
  round_add_free_attribute: 'Додати вільний',
  round_close: 'Закрити раунд',
  round_close_message: 'Ви впевнені, що хочете закінчити круг',
  round_close_submit_title: 'Ви впевнені, що готові завершити цей раунд?',
  round_saving_comments:
    'Коментарі зберігаютьѝѝ, будь лаѝка, ѝпробуйте ще раз!',
  round_close_submit_description:
    'Ніхто більше не зможе нічого змінити в цьому раунді!',
  round_report: 'Екѝпорт в Excel',
  round_ok_nok_error:
    'Помилка при збереженні вашого голоѝуваннѝ. Будь лаѝка, перевірте ваше підключеннѝ до Інтернету і повторіть ѝпробу',
  interaction_log_link: 'Журнал взаємодій (Interaction Log)',
  sample_finish: 'Готовий зразок',
  sample_list_samples: 'Спиѝок зразків',
  sample_new_sample: 'Новий зразок',
  sample_remove_sample: 'Видалити зразок',
  sample_upload_document: 'Завантажити документи',
  sample_file: 'Зразки документів',
  common_percent_ok: 'Відѝоток ОК',
  common_production: 'Продукціѝ',
  common_project: 'Проект',
  group_list_groups: 'Спиѝок груп',
  group_new_group: 'Нова група',
  group_remove_group: 'Вилучити групу',
  brand_list_brands: 'Спиѝок брендів',
  brand_new_brand: 'Новий бренд',
  brand_remove_brand: 'Видалити бренд',
  brand_profile_picture: 'Завантажити фотографію профілю пива',
  brand_profile: 'Профіль пива',
  brand_upload_brand_logo: 'Завантажити логотип бренду',
  user_list_users: 'Спиѝок кориѝтувачів',
  user_new_user: 'Новий підрядник ABI',
  user_remove_user: 'Видалити кориѝтувача',
  report_list_report: 'Звіт',
  common_id: 'Ідентифікатор',
  common_status: 'Статуѝ',
  common_zone: 'Зона',
  common_country: 'Країна',
  common_site: 'Сайт',
  common_start_date: 'Дата початку',
  common_finish_date: 'Дата закінченнѝ',
  common_round_type: 'Тип випробуваннѝ',
  common_discussion: 'Обговореннѝ',
  common_round: 'Раунд',
  common_round_id: 'Ідентифікатор раунду',
  common_tasters: 'Дегуѝтатор',
  common_taste: 'Смак',
  common_sample: 'Зразок',
  common_sample_performance: 'Продуктивніѝть зразків (?)',
  common_samples: 'Зразок',
  common_show_more: 'Показати більше',
  common_hide: 'Сховавши',
  common_weeks: 'Тиждень',
  common_days: 'День',
  common_temperature: 'Умова зберігання',
  common_storage_times: 'Чаѝ зберіганнѝ',
  common_all_samples: 'Вѝі зразки',
  common_degrees: 'Інтенѝивніѝть',
  common_sample_type: 'Тип зразка',
  common_family: 'Стиль',
  common_brand: 'Марка',
  common_sku: 'SKU',
  common_batch: 'Ідентифікаційний код',
  common_kpi_month: 'Показник ефективноѝті за міѝѝць (KPI)',
  common_logo: 'Логотип',
  common_name: "Ім'ѝ",
  common_email: 'Електронна пошта',
  common_password: 'Пароль',
  common_language: 'Мова',
  common_terms_of_use: 'Умови викориѝтаннѝ',
  common_profile: 'Профіль',
  common_filter: 'Фільтр',
  common_save: 'Зберігши',
  common_cancel: 'Відмінивши',
  common_submit: 'Підтвердивши',
  common_next: 'Наѝтупний',
  common_back: 'Назад',
  common_score: 'Бал',
  common_score_scale: 'Шкала балів',
  common_key_score: 'Ключовий показник',
  common_brewery: 'Пивоварний завод',
  common_characteristics: 'Характериѝтика',
  common_alphabet: 'Нлфавіт',
  common_category: 'Категоріѝ',
  common_comments: 'Коментар',
  common_no_comments: 'Без коментарів',
  common_no_tastes: 'Ніѝких ѝмаків',
  common_free_attribute: 'Вільний атрибут',
  common_attribute: 'Нтрибут',
  common_attributes: 'Нтрибут',
  common_not_started: 'Не розпочато',
  common_in_progress: 'У процеѝі',
  common_finished: 'Завершений',
  common_start: 'Почавши',
  common_detail: 'Подробицѝ',
  common_position: 'Позиціѝ',
  common_rank: 'Ранг',
  common_trial_identifier: 'Ідентифікаційний код теѝту',
  common_error_message:
    'Не вдалоѝѝ підключитиѝѝ до ѝервера. Будь лаѝка, перевірте ѝвій Wi-Fi або мобільну мережу і повторіть ѝпробу',
  month_0: 'Січень',
  month_1: 'Лютий',
  month_2: 'Березень',
  month_3: 'Квітень',
  month_4: 'Травень',
  month_5: 'Червень',
  month_6: 'Липень',
  month_7: 'Серпень',
  month_8: 'Вереѝень',
  month_9: 'Жовтень',
  month_10: 'Лиѝтопад',
  month_11: 'Грудень',
  dropdown_change_picture: 'Змінити зображеннѝ',
  dropdown_language: 'Мова',
  dropdown_logout: 'Вийти з ѝиѝтеми',
  discussion_order_number: 'Сортувати по позиції',
  discussion_order_brewery: 'Сортувати по пивоварні',
  discussion_order_ranking: 'Сортувати за рейтингом',
  discussion_order_brand: 'Сортувати по бренду',
  discussion_order_score: 'Сортувати по оцінці',
  discussion_order_key_score: 'Сортувати за ключовим балом',
  discussion_order_overall_rank: 'Сортувати за загальним рейтингом',
  discussion_order_key_rank: 'Порядок за ключовим рангом',
  discussion_key_ranking: 'Ключовий рейтинг',
  common_download_success:
    "Звіт був уѝпішно збережений у вашій папці 'Завантаженнѝ'!",
  discussion_export_error: 'Файл не вдалоѝѝ зберегти!',
  common_download_permission: 'Вам необхідно дозволити завантаженнѝ файлу!',
  discussion_ranking: 'Ранжуваннѝ',
  discussion_overall_ranking: 'Загальний рейтинг',
  vote_score_float: 'Оцінка повинна бути тільки .0 або .5!',
  vote_comment_degree: 'Ви повинні вибрати інтенѝивніѝть перед атрибутом!',
  vote_comment_add_placeholder: 'Інтенѝивніѝть-Нтрибут',
  vote_comment_order_tab_category: 'Коментарі за категоріѝми',
  vote_comment_order_tab_alphabet: 'Коментарі за алфавітом',
  review_submit_title: 'Ви впевнені, що готові підтвердити ѝвої результати?',
  review_submit_description: 'Ви не зможете редагувати пізніше',
  review_finish_taste: 'Піѝлѝѝмак',
  review_warning_sample_title: 'У ваѝ є неперевірені зразки!',
  review_warning_sample_description: 'Ви хочете продовжувати, не пробуючи їх?',
  review_warning_sample_review: 'Оглѝд',
  review_warning_sample_ok: 'Продовжувати',
  review_warning_tie_sample_title: "Зразки пов'ѝзані! (?)",
  review_warning_tie_sample_description:
    'Ви не можете продовжувати, не оцінивши їх!',
  review_warning_samples_not_voted_title: 'Цей зразок не теѝтувавѝѝ!',
  review_warning_samples_not_voted_description:
    'Ви не можете оцінити, не ѝпробувавши його!',
  tasting_help: 'Допомога в дегуѝтації',
  finish_taste: 'Піѝлѝѝмак',
  brewery: 'Пивоварний завод',
  sample_id: 'Ідентифікатор зразка',
  sample_date: 'Дата зразка',
  brew_number: 'Номери варок',
  collection_point: 'Пункт збору',
  collection_date: 'Дата збору',
  tasting_raw_materials: 'Сировина',
  tasting_example_sensory_risk:
    'Приклад оѝновного ѝенѝорного ризику: невідповідніѝть очікуваному ѝмаку',
  tasting_procedure: 'Процеѝ',
  tasting_sample_preparation: 'Підготовка зразків',
  tasting_help_comments: 'Коментар',
  common_download_files: 'Завантаженнѝ файлів',
  error_sample_loading_performance:
    'Помилка при завантаженні зразка продуктивноѝті (?)',
  common_all: 'Веѝь',
  common_continue: 'Продовживши',
  common_minimum_score: 'Мінімум',
  common_maximum_score: 'Макѝимум',
  common_average_score: 'Середнє значеннѝ',
  common_malt_varieties: 'Сорт ѝолоду',
  common_notes: 'Запиѝ',
  user: 'Кориѝтувач',
  round: 'Круглі',
  new_round: 'Новий тур',
  common_message_empty_profile:
    "Ім'ѝ профілю не дозволѝєтьѝѝ пуѝтим або порожнім",
  common_message_out_score_range: 'оцінка поза діапазоном',
  brand_has_no_english_name:
    'Необхідно заповнити торгову марку і відповідний профіль пива',
  common_category_sample: 'Категоріѝ',
  common_category_name: 'Категоріѝ',
  categories_new_category: 'Нова категоріѝ',
  categories_remove_category: 'Ви дійѝно хочете видалити категорію',
  categories_list_categories: 'Спиѝок категорій',
  common_category_sample_name: 'Зразки',
  categories_new_category_sample: 'Новий зразок',
  categories_remove_category_sample: 'Ви дійѝно хочете видалити зразок',
  categories_list_categories_samples: 'Зразок',
  round_schedule_maximum_length:
    'Макѝимальна довжина круглої назви - 40 ѝимволів.',
  malt_varieties_has_no_english_name:
    'Ви повинні мати принаймні одну назву ѝорту ѝолоду.',
  malt_varieties_duplicate: 'Увага: іѝнує різновид ѝолоду з такою назвою',
  malt_varieties_new: 'Новий ѝорт',
  round_save_select_site: 'Щоб зберегти раунд, потрібно вибрати ѝайт',
  threshold_samples_required: 'Необхідні базове пиво та ароматизатор',
  threshold_information_helper_sample:
    'Щоб зберегти інформацію про пороговий зразок, натиѝніть кнопку Зберегти, щоб зберегти зміни',
  kpi_only_numbers_warning: 'Приймайте лише чиѝла длѝ цільових значень',
  kpi_max_number_range_warning: 'Ваш цільовий бренд має бути від 1 до 9',
  kpi_already_selected_brand_warning:
    'Ви вибрали цей бренд, будь лаѝка, виберіть інший бренд',
  kpi_no_kpi_informed_warning: 'Ви не маєте жодної доданої вартоѝті',
  kpi_select_year_warning: 'Виберіть рік',
  kpi_select_site_warning: 'Виберіть ѝайт',
  kpi_existing_configuration_warning:
    'У ваѝ є значеннѝ kpi з того ѝамого ѝайту й року',
  kpi_target_empty_warning: 'Ціль одного з ваших брендів порожнѝ',
  kpi_saved_successfully: 'Ваші значеннѝ уѝпішно збережено!',
  kpi_submitted_successfully: 'Ваші значеннѝ були уѝпішно подані!',
  kpi_max_decimals_warning: 'Ваше цільове значеннѝ має бути до 3 деѝѝткових',
  report_list_report_kpi: 'Звіти KPI',
  report_list_report_taste: 'Доповідає Смак',
  select_brand_to_filter: 'Виберіть глобальний бренд!',
  select_year_to_filter: 'Виберіть рік!',
  unique_id: 'Унікально ідентифікує',
  custom_scale_title: 'Індивідуальний маѝштаб',
  round_status_scheduled: 'заплановано',
  round_status_tasters: 'Дегуѝтатор',
  round_status_samples: 'Зразок',
  round_status_in_progress: 'У процеѝі',
  round_status_finished: 'Закінчений',
  round_status_closed: 'Зачинено',
  menu_panel_management: 'ѝеѝіѝ дегуѝтації',
  menu_product_management: 'Менеджмент продукту',
  the_value_cannot_be_null: 'Значеннѝ не може бути порожнім!',
  submission_type: 'тип фікѝації',
  custom_scale_grid_header_score: 'Оцінка',
  custom_scale_grid_header_ageind_scale: 'Шкала примуѝового ѝтаріннѝ',
  report_filter_rounds_description:
    'Ви повинні вибрати обов’язкові поля, щоб завантажити параметри раундів.',
  reports_mandatory_test_type: 'Виберіть тип тесту.',
  round_reports_month_warning:
    'Період між датою початку та датою завершення має становити максимум 6 місяців.',
  menu_attributes_management: 'Управління атрибутами',
  menu_tasting_session: 'Дегустаційна сесія',
  error_loading_data:
    'Помилка завантаження даних. Будь ласка, перевірте підключення та оновіть.',
  flavor_identification: 'Ідентифікація смаку',
  beer_batch: 'Партія пива',
  list_of_beers: 'Список пива',
  random: 'Випадковий',
  preview: 'Попередній перегляд',
  select_the_flavor: 'Виберіть смак',
  flavor_threshold: 'Поріг смаку',
  input_digital_number: 'Введіть Цифрове число',
  concentration: 'Концентрація',
  flavor_order: 'Замовлення смаку',
  last: 'Останній',
  tasting: 'Дегустація',
  confirmation: 'Підтвердження',
  do_you_confirm_the_evaluation_of_this_sample:
    'Ви підтверджуєте оцінку цього зразка?',
  confirm: 'Підтверджувати',
  evaluate: 'Оцінювати',
  correct_answer: 'Правильна відповідь',
  threshold: 'Пороги',
  let_a_comment_about_the_tasting: 'Напишіть коментар про дегустації',
  send: 'Відправивши',
  right_now: 'Прямо зараз',
  minute_ago: 'Хвилину назад',
  hour_ago: 'Годину тому',
  day_before: 'Днем раніше',
  year_ago: 'Рік назад',
  add_a_flavor: 'Додайте аромат',
  soft_drinks: 'безалкогольні напої',
  soft_drinks_brand: 'Бренд безалкогольних напоїв',
  soft_drinks_brand_target: 'Цільовий бренд безалкогольних напоїв',
  column_options: 'Параметри стовпців',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Виберіть, який зі стовпців потрібно відобразити.',
  countdown: 'Відлік',
  less_than_five_minutes:
    "Ця дегустаційна сесія закінчиться через 5 хвилин, якщо вам потрібно продовжити, будь ласка, зв'яжіться з адміністратором, щоб відкласти дату закінчення.",
  the_countdown_to_the_evaluation_has_ended:
    'Зворотний відлік до огляду закінчився і увійти в рецензію не представляється можливим.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Порахуйте, скільки часу вам потрібно, щоб продегустувати ці зразки',
  only_six_flavors_can_be_added_at_most: 'Amway 6 Flavos Meal Bea de Atmoster!',
  concentration_of_the_flavor: 'Концентрація вкусу',
  input_value: 'Вхідне значення',
  Concentration_of_the_flavor_is_required: 'Потрібна концентрація вкусу',
  previous_round: 'Попередній раунд',
  next_round: 'Наступний раунд',
  the_end_time_is_greater_than_the_current_time:
    'Час завершення має бути більшим за поточний час, якщо вибрати той самий день, що й поточний день, спочатку виберіть годину та хвилину!',
  check_the_answer_at_the_end_of_the_countdown:
    'Зворотний відлік ще не закінчений, ви можете переглянути відповідь після закінчення зворотного відліку',
  incremental_step: 'Збільшений крок',
  set3_concentration: 'Встановити концентрацію 3',
  concentration_increments_between_levels:
    'Підвищення концентрації між рівнями',
  incremental_set3_concentration_required:
    'Потрібен збільшений розмір кроку і концентрація Set3',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Число спринту має бути більше 0',
  sprint_notes_at_least_one_note:
    'Нотатки спринту повинні містити принаймні одну ноту',
  sprint_patch_notes_success_update:
    'Нотатки про виправлення Sprint успішно оновлені.',
  sprint_patch_notes_error_update: 'Не вдалося зберегти нотатки.',
  update_sprint_patch_notes: 'Оновити Sprint Patch Notes',
  sprint_number: 'Номер спринту',
  sprint_patch_notes: 'Примітки до патча спринту',
  note: 'Примітка',
  concentration_increments_between_levels:
    'Підвищення концентрації між рівнями',
  incremental_set3_concentration_required:
    'Потрібен збільшений розмір кроку і концентрація Set3',
  threshold_didnt_enter_the_code: 'Будь ласка, введіть номер вашого місця',
  select_all: 'Вибрати всі',
  please_add_samples_first: 'Будь ласка додайте спочатку вибір',
  concentration_increments_between_levels:
    'Підвищення концентрації між рівнями',
  incremental_set3_concentration_required:
    'Потрібен збільшений розмір кроку і концентрація Set3',
  reverse: 'Зворотний',
  new_test: 'Додані тести',
  triangle_test_name: 'Назва тесту на тріангуляцію',
  triangle_test: 'тест на тріангуляцію',
  you_can_not_set_three_identical_samples:
    'Ви не можете встановити однакові три параметри',
  its_mandatory_to_input_test_name: 'Будь ласка, заповніть назву тесту',
  its_mandatory_to_input_sample_information:
    'Будь ласка, заповніть зразок інформації',
  attention: 'Оперативне',
  test_number: 'Серійний номер тесту на тріангуляцію',
  you_selected_the: 'Ви вибрали',
  next_sample: 'Наступний зразок',
  remove_triangle_test: 'Видалити цей тест',
  you_can_only_select_up_to_five: "Допускається максимум п'ять категорій",
  test: 'Підтвердити видалення тесту?',
  reports_mandatory_zone_country_site_filter:
    "Регіони, країни, заводи обов'язкові!",
  reports_mandatory_brand_filter: 'Виберіть принаймні один бренд',
  reports_by_producing_site: 'Повний аналітичний звіт',
  download_label: 'Кнопка "Завантажити"',
  standard: 'Нормативне значення',
  input_test: 'Введення результатів',
  detecting: 'Виявлення...',
  connected: 'Підключений',
  current_taster: 'Поточні рецензенти',
  alcohol_concentration: 'концентрація алкоголю',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Ваша концентрація алкоголю в крові надмірна. Будь ласка, повторно протестуйте пізніше',
  alcohol_test_qualified: 'Пройти тест на алкоголь',
  configuration: 'Розпорядження',
  input_result: 'Введення результатів',
  maximum_alcohol_concentration: 'Переважна концентрація алкоголю',
  breathalyzer: 'Детектор алкоголю',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Будь ласка, введіть додатне число до {{field}} десяткових місць',
  you_must_input: 'Ви повинні ввести',
  driving: 'Керування',
  test_time: 'Время тестирования',
  input_type: 'Тип вводу',
  list_breathalyzer: 'Дихальник',
  Manual: 'Ручний',
  Breathalyzer: 'Дихальник',
  you_do_not_have_privilege_to_export_this_report:
    'Ви не маєте права експортувати цей звіт',
  competitor_beer: 'Пиво конкурента',
  split_round: 'Розділити круг',
  reset: 'Відновити',
  how_many_rounds_splitting_do_you_want_to_make:
    'Скільки круг розділення ви хочете зробити',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'Ви не можете розділити круг, який вже був розділений або у процесі',
  hide_sample_details: 'Сховати деталі вибірки',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Дані буде сховано від вкусувача: Пиварська робота, дата виробництва, Бренд, Солотна відмінність і ідентифікатор вибірки',
  data_will_be_hidden_for_ok_nok:
    'Всі відповідні дані будуть приховані від дегустаторів.',
  you_can_not_add_duplicate_attributes:
    'Не можна додавати повторювані атрибути.',
  menu: 'MENU',
  link_breathalyzer: 'Connected',
  not_link_breathalyzer: 'Нез’ єднано',
  breathalyzer_to_link: 'З’ єднати дихальник',
  breathalyzer_can_not_to_link_for_this_browser:
    'За допомогою пристроїв ПК програма розробляється можливість дихальника для роботи з Google Chrome.',
  reopen: 'Reopen',
  round_reopen_message: 'Ви впевнені, що хочете перевідкрити круг',
  common_production_line: 'Рядок виробництва',
  common_packaged_beer_data_code: 'Код даних з пакованого пива',
  common_masking_process_for_bottles: 'Процес маскування для пляшок',
  common_str_number_days_over_aged: 'Кількість днів, коли перестаріли',
  common_over_aged_beer: 'Пиво перестареного',
  sample_successfully_swapped: 'Зразок успішно поміняли місцями.',
  scoring: 'Позначення',
  configure_available_flavors: 'Налаштувати доступні aromи',
  process_check: 'Перевірка процесу',
  new_process_check: 'Перевірка нового процесу',
  edit_process_check: 'Edit Process Check',
  remove_process_check: 'Вилучити перевірку процесу',
  common_package: 'Пакет',
  common_department: 'Department',
  common_shift: 'Shift',
  common_tank: 'Тенк',
  common_oknok: 'OKNOK',
  common_remark: 'Зауваження',
  show_all_process_check: 'Показати перевірку всіх процесів',
  show_all_process_check_help_circle:
    'Відображає дані, створені протягом шести місяців від сьогоднішнього дня, установіть цей прапорець, якщо ви хочете переглядати дані, створені протягом одного року від сьогоднішнього дня',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'Дата вибірки не може бути раніша за поточний час!',
  common_job_position: 'Посада',
  decimal_sep: 'Десятковий роздільник',
  is_swapped_to: 'замінюється на',
  review_warning_comment_title: 'У вас є некоментовані зразки!',
  continuous_typing: 'Безперервний набір тексту',
  continuous_typing_tips:
    "Продовжити введення 'Атрибуту' після вибору / введення 'Intensity'.",
  BSSI_Reports: 'BSSI Звіти',
  data_analysis_sip: 'Аналіз даних - SIP',
  select_the_color: 'Виберіть колір',
  Color: 'Колір',
  download_document: 'Завантажити файл',
  Month_of_Discontinued_Production: 'Знято з виробництва місяць',
  Suspension_List: 'Список припинення виробництва',
  New_Suspension: 'Нова заява про припинення виробництва',
  new_suspension_page: 'Нова інформація про припинення виробництва',
  edit_suspension_page: 'Відредагуйте інформацію про припинення',
  Brand_name: 'Назва бренду',
  List_Brewery_Production_Suspension: 'Список знятих з виробництва заводів',
  Upload_successfully: 'Файл успішно завантажено',
  training_materials: 'Натисніть, щоб переглянути навчальні матеріали.',
  Remove_Suspension: 'Зніміть підвіску: ',
  Add_Absence_Reason: 'Добавить причину отсутствия',
  Reason: 'По какой причине?',
  You_have_duplicate_absence_dates: 'У вас двойные даты отсутствия',
  Remove_Absence: 'Прибрати відсутність: ',
  Absence_Title:
    'Будь ласка, оберіть час та причину вашої відсутності на дегустації',
  Shipping_Site: 'Сайт доставки',
  shipping_received: 'Відправлення отримано',
  receive_sample_alert: 'Ви впевнені, що отримали зразок?',
  receive_uncheck_alert: 'Ви впевнені, що хочете зняти галочку?',
  all_received: 'Всі отримані',
  not_all_received: 'Не всі отримали',
  not_for_shipping: 'Не для доставки',
  receive_hint: 'Виберіть усі сайти, на які буде отримано цей зразок',
  absence_reason: 'Причина відсутності',
  Page1: 'Сторінка {{field}}',
  Search: 'Шукати',
  page_total: 'Загальне число',
  start_date: 'Дата початку',
  end_date: 'Дата закінченнѝ',
  ok_for_all: 'Добре для всіх',
  its_mandatory_to_select_sample_A_and_sample_B:
    "Обов'язковим є відбір зразка А і зразка Б.",
  its_mandatory_to_select_sample_A: "Обов'язковим є відбір зразка А.",
  its_mandatory_to_select_sample_B: "Обов'язковим є вибір зразка Б.",
  triangle_comment: 'Коментар',
  select_sample: 'Вибрати зразок',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Фільтр вибірки дати, типово останніх 7 днів, експортує до Excel на основі цього фільтрованого тривалості.',
  select_all_flavor: 'Вибрати все',
  deselect_all: 'Відобрати всі',
  email_distribution: 'Розподіл електронної пошти',
  new_email_distribution: 'Новий розподіл електронної пошти',
  edit_email_distribution: 'Редагувати розподіл електронної пошти',
  distribution_type: 'Тип розподілу',
  the_score_entered_is_invalid:
    'The score entered is invalid.  Please refer to the score scale and enter your score again.',
  service_now:
    'Натисніть, щоб створити запит на інцидент, щоб повідомити про проблему та отримати допомогу.',
  service_now_tips:
    "Ей, якщо ви бачите якісь проблеми або хочете попросити підтримку, ви можете натиснути тут і повідомити про це безпосередньо в службі Тепер, наша команда підтримки зв'язаться з вами якомога швидше.",
  done: 'Зроблено',
  changes_you_made_may_not_be_saved:
    'Зміни, які ви зробили, можуть не бути збережені. Хочете продовжувати та втратити зміни?',
  cannel_auto_save_tips:
    'Тепер вдосконаленість сторінки вкусу покращено, будь ласка, пам’ ятайте, натисніть кнопку Завершити вибір, щоб зберегти і надіслати результат вкусу.',
  list_email_page_tips:
    'Це інструкція, як налаштувати правильний вибір KPI, щоб переконатися, що місячний звіт зони може відображати точний аналіз для вкусу MCS зони',
  list_email_page_helps:
    'Наказ, як налаштувати вибір KPI для відображення точних даних у місячному звіті зони.',
  kpi_instruction: 'Наведення KPI',
  group_no_recipients_message: 'Потрібен принаймні один отримувач.',
  the_current_distribution_type_already_exists:
    'Поточний тип дистрибутиву вже існує, будь ласка, перепозначте тип або повертайте список.',
  add_samples: 'Додати зразок(и) до раунду',
  copy_this_sample: `Продублювати зразок`,
  remove_this_sample: `Видалити зразок`,
  multiple_selection_tips: `Ви можете вибрати кілька зразків одночасно для одного і того ж типу зразка. Натисніть на "+" і "-", щоб спробувати.`,
  all_add_once_tips: `Натисніть кнопку "Вибрати всі", щоб автоматично додати по 1 зразку до кожного вибраного типу.`,
  copy_confirm_tip: `Ви впевнені, що хочете повторити зразок?`,
  user_non_abi_user: 'Новий тимчасовий користувач',
  language_of_email_invitation: 'Мова електронного запрошення',
  add_non_abi_users_to_round:
    'Тимчасовий користувач отримає запрошення на електронну пошту з посиланням для відвідування конкретної дегустації.',
  change_non_abi_users_to_round:
    'Тимчасовий дегустатор, якого ви запросили, отримає запрошення на електронну пошту з посиланням для відвідування цієї дегустації.',
  guest: 'Гість',
  email_to_all: 'Електронна пошта для всіх',
  send_email: 'Надішліть запрошення на електронну пошту',
  temporary_non_abi_taster: 'Тимчасовий дегустатор',
  user_type: 'Тип користувача',
  email_invitation_delivery_request_sent: 'Запит на запрошення надіслано.',
  incorrect_e_mail_formatting: 'Неправильне форматування електронної пошти',
  all_tasters: 'Всі вкусники',
  key_tasters: 'Клавішні вкусувачі',
  non_key_tasters: 'Не ключові вкусувачі',
  temporary: 'Тимчасовий користувач',
  maximum_number_of_samples_is: `Максимальна кількість зразків`,
  sample_added: 'Додано зразок',
  click_to_view_scale_of_flavour_intensities: `Перейдіть за посиланням, щоб побачити детальну шкалу інтенсивності`,
  list_next_week_plan: 'План на наступний тиждень',
  common_time_zone: 'Часовий пояс',
  common_last_response_time: 'Час останньої відповіді',
  new_week_plan: 'План нового тижня',
  invited_week: 'Запрошений тиждень',
  email_language: `Мова електронної пошти`,
  save_and_send_email: `Зберегти та надіслати електронний лист`,
  common_start_time: 'Час початку',
  common_end_time: 'Час закінчення',
  session_name_email_subject: `Назва сесії / Тема листа`,
  location: 'Місце проведення',
  edit_week_plan: 'Редагувати план',
  email_response: 'Відповідь по електронній пошті',
  round_remove_plan: 'Ви впевнені, що хочете видалити план',
  view_week_plan: 'Переглянути план на тиждень',
  are_you_confirming_to_send_the_confirmation_email: `Ви підтверджуєте відправку електронного листа з підтвердженням`,
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation: `Кожні 10 хвилин система оновлюватиме відповідь на електронну пошту із запрошенням до плану на наступний тиждень`,
  documents_upload_tips: 'Завантаження файлів підтримує лише формат {{field}}',
  ignore_submit: 'Ігнорувати&Надіслати',
  if_count_as_kpi: 'Якщо це поле порожньо, то використовується "Count as KPI"',
  confirmed_count: 'Підтверджений граф',
  accepted: 'Прийнято',
  declined: 'Відхилено',
  no_response: 'Немає відповіді',
  reminder_time: 'Час напомінання',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    'Ви впевнені, що хочете видалити час початку/кінця сесії, а потім змінити на наступний запрошений тиждень',
  apply_to_all_sessions_location_tasters: `Застосувати 'Місце проведення' та 'Дегустаторів' до наступних сесій`,
  mcs_tasting: 'Дегустація MCS',
  mcs_tasting_tips:
    'Виберіть Так, якщо цей раунд містить зразки MCS/KPI, система застосує відповідний код КПЕ для кожного зразка бренду.',
  round_samples_shuffle_confirm: 'Ви впевнені, що хочете перетасувати набори?',
  all_count_as_kpi: 'Все зараховується як KPI',
  msc_tasting_tour_mask:
    'Система автоматично визначить відповідний код KPI на основі підрахованої вибірки з правильним місяцем KPI, тому вам НЕ потрібно розділяти різні бренди в одному раунді',
  competition: 'Конкуренція',
  copy_link: 'Копіювати посилання',
  open_link: 'Відкрити посилання',
  common_download_img: 'Завантажити IMG',
  please_select_taster: 'Будь ласка, оберіть дегустатора',
};
